import Lottie from 'react-lottie';
import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import * as animationData from './animation.json'

function App({color, containerStyle, containerWidth}) {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return <>
        <div id="overlay"></div>
        <div className="loader-main" style={{
            position: "absolute",
            top: 0,
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            height: "100vh", 
            width: containerWidth ? containerWidth : "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 200,
            ...(containerStyle ? containerStyle : {})
        }}>
            <span className="lottie-container">
                <Lottie options={defaultOptions} height={200} width={200}/>
            </span>
            <div className="spinner-container">
                <ClipLoader color={color ? color : "#006039"} size={300}/>
            </div>
        </div>
    </>
}

export default App;
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { cancelIcon, clock, dummy2, threeDots, user } from '../../assets'
import { authReq } from '../../requests'
import './appointmentView.css'
import { bookAppointmentGAPI } from '../../pages/admin/appointments/Appointments'

const dayMapping = {
    "Sun": "Domingo",
    "Mon": "Lunes",
    "Tue": "Martes",
    "Wed": "Miércoles",
    "Thu": "Jueves",
    "Fri": "Viernes",
    "Sat": "Sábado"
};

// const AppointmentView = (data) => {
//     const updateStatus = async () => {
//         await authReq(
//             "PATCH",
//             `/appointment/${props._id}`,
//             { status: "patient-canceled" }
//         )
//         data.setRefresh(!refresh)
//     }

//     const props = data.appointment
//     props.free = props.slot?.includes(':45')
//     const { onCancel, onAccept, onReschedule, onCall } = data
//     const [modelShow, setModelShow] = useState(false)
//     const [refresh, setRefresh] = useState(false)
//     const [currentDate, setCurrentDate] = useState(Date.now())

//     useEffect(() => {
//         setInterval(() => {
//             setCurrentDate(Date.now())
//         }, 1000)
//     }, [])

//     const navigate = useNavigate()
//     return (
//         <div className='kwn-appointment_view'>
//             <div className='kwn-side_divider' />
//             <div className='kwn-appointment_content_top_view'>
//                 <div className='kwn-appointment_title_view'>
//                     <h2>Appointment date</h2>
//                 </div>
//                 <div className='kwn-appointment_time_view'>
//                     <img src={[console.log(props), clock][1]} />
//                     <h2 style={props?.rescheduleRequestedBy ? { textDecoration: 'line-through' } : {}}>{props.type === 'patient-completed' || props.type === 'patient-canceled' ? `${new Date(props.appointmentStart).toLocaleString()}` : `${new Date(props.appointmentStart).toLocaleString()}`}</h2>
//                 </div>
//                 {props?.rescheduleRequestedBy && <div className='kwn-appointment_time_view'>
//                     <img src={[console.log(props), clock][1]} />
//                     <h2>{new Date(props.rescheduleAppointmentStart).toLocaleString()}</h2>
//                 </div>}
//                 <div className='kwn-appointment_divider' />
//                 <div className='kwn-appointment_profile_view'>
//                     <img src={props.appointer?.image} />
//                     <div className='kwn-appointment_name_view'>
//                         <h3>{props.appointer?.name}</h3>
//                         <h4>{props.appointer?.preferences?.speciality ?? ''}</h4>
//                         {props.rescheduleRequestedBy == props?.appointee?._id  && <h4 style={{ fontSize: '11px' }}>Requested Rescheduling to {new Date(props.rescheduleAppointmentStart).toLocaleString()}</h4>}
//                     </div>
//                 </div>
//                 <div style={{ margin: '2px' }}></div>
//                 {console.log("PROP TYPE =?", props.rescheduleRequestedBy, props?.appointer?.name, "_:>", props.rescheduleRequestedBy == props?.appointee?._id)}
//                 {props.type === 'patient-pending' || props.rescheduleRequestedBy == props?.appointer?._id ? <div className='kwn-appointment_buttons_view'>
//                     <div className='kwn-appointment_button' style={{
//                         width: '31%',
//                         cursor: (props?.rescheduleAppointmentEnd ? props?.rescheduleAppointmentEnd  : props.appointmentEnd) + 5*60*1000 < currentDate ? "default" : "pointer",
//                         opacity: (props?.rescheduleAppointmentEnd ? props?.rescheduleAppointmentEnd  : props.appointmentEnd) + 5*60*1000 < currentDate ? 0.5 : 1
//                     }} onClick={() => !((props?.rescheduleAppointmentEnd ? props?.rescheduleAppointmentEnd  : props.appointmentEnd) + 5*60*1000 < currentDate) && onCancel(props)}>
//                         <h3>CANCEL</h3>
//                     </div>
//                     <div className='kwn-appointment_button' style={{
//                             width: '31%',
//                             color: 'black',
//                             backgroundColor: 'rgb(248, 184, 78)',
//                             cursor: (props?.rescheduleAppointmentEnd ? props?.rescheduleAppointmentEnd  : props.appointmentEnd) + 5*60*1000 < currentDate ? "default" : "pointer",
//                             opacity: (props?.rescheduleAppointmentEnd ? props?.rescheduleAppointmentEnd  : props.appointmentEnd) + 5*60*1000 < currentDate ? 0.5 : 1
//                         }} onClick={async () => {
//                         if((props?.rescheduleAppointmentEnd ? props?.rescheduleAppointmentEnd  : props.appointmentEnd) + 5*60*1000 < currentDate) return
//                         onAccept(props)
//                         console.log("MY-APPOINTMENT", props)
//                         await bookAppointmentGAPI(props)
//                     }}>
//                         <h3 style={{ color: 'black' }}>ACCEPT</h3>
//                     </div>
//                     <div className='kwn-appointment_button' style={{
//                         width: '31%', 
//                         order: "0.7px solid #006039",
//                         backgroundColor: '#fff'
//                     }} onClick={() => onReschedule(props, props.free, props.token)}>
//                         <h3 style={{ color: '#006039' }}>RESCHEDULE</h3>
//                     </div>
//                 </div> : (props.type == 'patient-canceled' || props.type == "patient-completed" || props.rescheduleRequestedBy == props?.appointee?._id ? <></> : <div className='kwn-appointment_buttons_view'>
//                         <div className='kwn-appointment_button' style={{ border: "0.7px solid #006039", backgroundColor: '#fff', color: '#006039' }} onClick={() => onReschedule(props._id, props.free, props.token)}>
//                             <h3 style={{ color: '#006039' }}>RESCHEDULE</h3>
//                         </div>
//                         {console.log("ltx", props)}
//                         <div className='kwn-appointment_button' style={{
//                             backgroundColor: '#006039',
//                             opacity: props.appointmentStart - 5*60*1000 > currentDate || props.appointmentEnd + 5*60*1000 < currentDate ? 0.5 : 1
//                         }} onClick={() => onCall(props, props.free, props.token)}>
//                             <h3>JOIN</h3>
//                         </div>
//                 </div>)}
//             </div>
//         </div>
//     )
// }

export const spanishDays = {
    0: "Domingo",
    1: "Lunes",
    2: "Martes",
    3: "Miércoles",
    4: "Jueves",
    5: "Viernes",
    6: "Sábado",
}

export const spanishMonths = {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre'
}

const AppointmentView = (props) => {
    console.log("prooopooos", props)

    const [appointment, setAppointment] = useState(props.appointment)
    appointment.free = props.slot?.includes(':45')
    const { onCancel: onCancel_, onAccept: onAccept_, onReschedule: onReschedule_, onCall: onCall_ } = props
    const [currentDate, setCurrentDate] = useState(Date.now())

    useEffect(() => {
        setAppointment(props?.appointment)
    }, [props?.appointment])

    const updateStatus = actualStatus => app => ({ ...app, actualStatus })
    const { onCancel, onAccept, onReschedule, onCall } = {
        onCancel: () => [updateStatus('patient-canceled'), onCancel_(appointment)],
        onAccept: () => [updateStatus('patient-upcoming'), onAccept_(appointment)],
        onReschedule: () => [updateStatus('patient-reschedule'), onReschedule_(appointment)],
        onCall: () => onCall_(appointment),
    }

    useEffect(() => {
        setInterval(() => {
            setCurrentDate(Date.now())
        }, 1000)
    }, [])

    const navigate = useNavigate()

    return (
        <div className="calendar-event">
            <div className={`calendar-event-inner ${props?.givenId}`}>
                <div className="event-person-detail">
                    <div className="event-header">
                        <div className="date-time">
                            {spanishDays[new Date(appointment?.appointmentStart).getDay()]}, {new Date(appointment?.appointmentStart).getDate()} {spanishMonths[new Date(appointment?.appointmentStart).getMonth()]}
                            <br/><span style={appointment?.rescheduleRequestedBy && { textDecoration: 'line-through' }}>{new Date(appointment?.appointmentStart - new Date().getTimezoneOffset()*60_000).toISOString().split('T')[1].split('.')[0].split(':').slice(0, 2).join(':')} - {new Date(appointment?.appointmentEnd - new Date().getTimezoneOffset()*60_000).toISOString().split('T')[1].split('.')[0].split(':').slice(0, 2).join(':')}</span>
                            <br/>
                            <span style={{ fontSize: 14 }}>
                                {console.log(`new Date(appointment?.rescheduleAppointmentStart`, new Date(appointment?.rescheduleAppointmentStart - new Date().getTimezoneOffset()*60_000))}
                                {appointment?.rescheduleAppointmentStart &&`${new Date(appointment?.rescheduleAppointmentStart - new Date().getTimezoneOffset()*60_000).toISOString().split('T')[1].split('.')[0].split(':').slice(0, 2).join(':')} - ${new Date(appointment?.rescheduleAppointmentEnd - new Date().getTimezoneOffset()*60_000).toISOString().split('T')[1].split('.')[0].split(':').slice(0, 2).join(':')}`}
                            </span>
                        </div>
                        {console.log("appointment?.actualStatus", appointment?.actualStatus)}
                        <div>
                            {
                                appointment?.actualStatus == 'patient-upcoming' || (appointment?.actualStatus == 'patient-reschedule' && appointment?.appointee?._id == appointment?.rescheduleRequestedBy) || (appointment?.actualStatus == 'patient-reschedule' && appointment?.rescheduleRequestedBy == null) ? <div>
                                    <img className='cancel-icon' src={cancelIcon} onClick={onCancel} style={{ height: 25, width: 25, cursor: 'pointer' }} />
                                </div> : <></>
                            }
                            {
                                appointment?.actualStatus == 'patient-pending' || (appointment?.actualStatus == 'patient-reschedule' && appointment?.appointer?._id == appointment?.rescheduleRequestedBy) ? <button className="accept-button" style={{ width: '110px', height: 32 }} onClick={onAccept}>Aceptar</button> : <></>
                            }
                        </div>
                    </div>
                    <div className="event-details">
                        <div>
                            <div className='event-details-main'>
                                <div className="avatar">{appointment?.patient?.name?.[0]?.toUpperCase?.()}{appointment?.patient?.name?.[1]?.toLowerCase?.()}</div>
                                <div className="event-name">{appointment?.patient?.name}</div>
                            </div>
                            <div className='calendar-event-status-text'>
                                <p>{
                                    appointment?.isFreeSessionFromCompany ? 'Sesión empresa' : (
                                        appointment?.pack ? `Sesión ${appointment?.numberOfSessions} de ${appointment?.totalSessions}` : (
                                            'Sesión individual'
                                        )
                                    )
                                }</p>
                            </div>
                        </div>
                        <div className="buttons">
                            {
                                appointment?.actualStatus == 'patient-pending' && <>
                                    <button className="reschedule-button" onClick={onReschedule}>Reagendar</button>
                                    <button className="cancel-button" onClick={onCancel}>Cancelar</button>
                                </>
                            }
                            {
                                appointment?.actualStatus == 'patient-completed' && <>
                                    <p style={{ color: 'red', textAlign: 'center' }}>Sesión completeda</p>
                                </>
                            }
                            {
                                appointment?.actualStatus == 'patient-canceled' && <>
                                    <p style={{ color: 'red', textAlign: 'center' }}>Sesión cancelada</p>
                                    <button className="reschedule-button" onClick={onReschedule}>Reagendar</button>
                                </>
                            }
                            {
                                appointment?.actualStatus == 'patient-upcoming' && <>
                                    {/* <img src={cancelIcon} onClick={onCancel} style={{ height: 25, width: 25, cursor: 'pointer' }}/> */}
                                    <button className="reschedule-button" onClick={onReschedule}>Reagendar</button>
                                    <button className="join-button" onClick={onCall}>Unirme a la sesión</button>
                                </>
                            }
                            {
                                appointment?.actualStatus == 'patient-reschedule' && appointment?.appointee?._id == appointment?.rescheduleRequestedBy && <>
                                    {/* <img src={cancelIcon} onClick={onCancel} style={{ height: 25, width: 25, cursor: 'pointer' }}/> */}
                                    <button className="reschedule-button" onClick={onReschedule}>Reagendar</button>
                                </>
                            }
                            {
                                appointment?.actualStatus == 'patient-reschedule' && appointment?.appointer?._id == appointment?.rescheduleRequestedBy && <>
                                    <button className="reschedule-button" onClick={onReschedule}>Reagendar</button>
                                    <button className="cancel-button" onClick={onCancel}>Cancelar</button>
                                </>
                            }
                            {
                                appointment?.actualStatus == 'patient-reschedule' && appointment?.rescheduleRequestedBy == null && <>
                                    <button className="reschedule-button" onClick={onReschedule}>Reagendar</button>
                                    <button className="join-button" onClick={onCall}>Unirme a la sesión</button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppointmentView

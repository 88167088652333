import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { bell, clock, dropdown, dummy2, info } from "../../assets";
import { TooltipContent } from "../../pages/admin/appointments/Appointments";
import { Loader } from "../../components";
import { authReq } from "../../requests";
import { baseURL } from "../../constants";
import "./rightSideBar.css";
import socketIO from "socket.io-client";

const socket = socketIO(baseURL);

const notificationText = (notification) => {
  let str = "";
  if (notification.status == "call-started") str = "Ha comenzado una llamada";
  else if (notification.notifyType == "appointment-booked")
    str = "Tienes una nueva solicitud";
  else if (notification.notifyType == "patient-reschedule")
    str = "Haga clic aquí para ver la solicitud";
  else if (notification.notifyType == "patient-canceled")
    str = "Tu sesión ha sido cancelada";
  console.log("NOTIFICATIOPN =?", notification, "STR >=", str);

  if (!str) return null;
  return str;
}

var appointmentStatusChanging = false
const setAppointmentStatusChanging = bool => {
  if(bool) appointmentStatusChanging = bool
  else setTimeout(() => {
    appointmentStatusChanging = bool
  }, 5000);
}

const RightSideBar = () => {
  const navigate = useNavigate();
  const [showDropdow, setShowDropdown] = useState(true);
  const [notificationArray, setNotificationArray] = useState([]);
  const [appointmentArray, setAppointmentArray] = useState([]);
  const [chatsArray, setChatArray] = useState([]);
  const [user, setUser] = useState({});
  const [notifcationLoading, setNotificationLoading] = useState(true);
  const [chatLoading, setChatLoading] = useState(true);
  const [appointmentLoading, setAppointmentLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if(!user?._id) return;
    // setInterval(() => {
      // socket.emit("get-appointments", { userId: user._id })
    // }, 1000); 
    authReq('GET', '/appointment?currentDate=' + Date.now(), (data) => {
      console.log("right sidebar data", data?.data?.appointments?.filter(
        (x) =>
          new Date(x.appointmentStart).toISOString().split("T")[0] ===
          new Date().toISOString().split("T")[0]
      ));
      // if(appointmentStatusChanging) return
      setAppointmentArray(
        data?.data?.appointments
          ?.filter(
            (x) =>
              new Date(x.appointmentStart).toISOString().split("T")[0] ===
              new Date().toISOString().split("T")[0]
          )
          ?.map((app) => {
            const startDate = new Date(app.appointmentStart).toISOString();
            const endDate = new Date(app.appointmentEnd).toISOString();
            return {
              ...app,
              color: "#ff9747",
              id: app._id,
              patient: app.appointer,
              startDateUTC: app.appointmentStart,
              endDateUTC: app.appointmentEnd,
              startDate: startDate,
              endDate: endDate,
              actualStatus: app.status,
              title: `Appointment date`,
              free: app.slot.includes(":45"),
              token: app.token,
              status:
                app.status == "patient-pending"
                  ? "join"
                  : app.status == "patient-completed"
                  ? "none"
                  : undefined,
            };
          })
          ?.map((app) => {
            return {
              ...app,
              id: app._id,
              title: app.appointer.name,
              disease: app?.appointer?.preferences?.speciality,
              image: app.appointer.image,
            };
          }) ?? []
      );
      setAppointmentLoading(false)
    });

    return () => {
      socket.off("unsub-appointments")
      socket.emit("unsub-appointments", { userId: user._id })
    }
  }, [user]);

  useEffect(() => {
    if(!user?._id) return;
    socket.emit("get-inboxes", { userId: user._id, once: true, max: 5 })
    socket.on("inboxes", res => {
      console.log(
        "ibxes",
        res
      )
      setChatArray(res.data.inboxes)
    })
  }, [user])

  useEffect(() => {
    authReq("GET", "/notification").then((data) => {
      setNotificationArray(
        data?.notifications.map((x, id) => {
          return {
            id,
            title: notificationText(x),
          };
        })
      );
      setNotificationLoading(false)
    });
  }, []);

  useEffect(() => {
    authReq("GET", "/user/me").then((data) => {
      const user = data.data;

      //   console.log(user, "user");
      setUser(user);
    });
  }, [refresh]);

  return (
    <div className="kwn-right_side_bar-main_container">
      <div className="kwn-right_side_bar-img_container">
        <div className="kwn-right_side_bar-profile_img">
          <img src={user?.image} onClick={() => navigate("/dashboard/SettingStack/profile")} />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px', marginTop: '-20px' }}>
        <h4 style={{
          fontFamily: "Inter",
          fontSize: "20px",
          color: "white",
        }}>{user?.name}</h4>
      </div>
      <div className="kwn-right_side_bar-top_notification_container">
        <div className="kwn-right_side_bar-notification_container">
          <div className="kwn-right_side_bar-notification_title">
            <img src={bell} className="kwn-right_side_bar-bell_icon" />
            <h3>Notification</h3>
            <img
              onClick={() => setShowDropdown(!showDropdow)}
              src={dropdown}
              className="kwn-right_side_bar-dropdown"
            />
          </div>
          {showDropdow && (
            <div className="kwn-right_side_bar-notification_content">
              {notificationArray.map((item) => {
                return <p key={item.id}>{item.title}</p>;
              })}
            </div>
          )}
        </div>
      </div>
      <div className="kwn-right_side_bar-today_appoint_container">
        <h1>today appointments</h1>
        {appointmentArray
          .filter((_, i) => i < 2)
          .map(app => {
            return <TooltipContent
              key={JSON.stringify(app)}
              className="scheduler-content-item-card scheduler-sidebar-margin" 
              appointmentData={app}
              tooltip={<></>}
              appointmentStatusChanging={appointmentStatusChanging}
              setAppointmentStatusChanging={setAppointmentStatusChanging}
              setTooltip={() => {}}
              schedulerData={appointmentArray}
              small={true}
              setSchedulerData={setAppointmentArray}
              borderColor={app.status == "join" ? "#9CC4B2" : app.status == "none" ? "rgb(77, 123, 185)" : "#006039"}
            />;
          })}
      </div>
      <div className="kwn-right_side_bar-chat_container">
        <h1>Recent Chats</h1>
        <div className="kwn-right_side_bar-chat_profiles">
          {chatsArray.slice(0, 5).map((item) => {
            return (
              <div className="kwn-right_side_bar_chat">
                <img
                  onClick={() => navigate("/dashboard/ChatStack")}
                  src={item.image}
                />
                <p>{item.name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RightSideBar;

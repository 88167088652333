import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Footer, TextInput, Button } from "../../../components";
import { req } from "../../../requests";
import "./forgotPassword.css";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  return (
    <div>
      <Navbar />
      <div className="kwn-fill_out_form-main_container">
        <div className="kwn-fill_out_form-title_container">
          <h1>Email Address</h1>
          <h2>Enter Email Associated with your account</h2>
        </div>
        <div className="kwn-forgot_password-form_container">
          <TextInput
            type={"text"}
            title={"Email"}
            value={email}
            onChange={ev => setEmail(ev.target.value)}
            placeholder={"Johndoe@mail.com"}
          />
        </div>
        <h2 style={{ color: 'red' }}>{error}</h2>
        <div className="kwn-forgot_password-Button_container">
          {console.log("ok>>", email)}
          <Button disabled={!email} onClick={async () => {
            if(!email) return
            let success = true
            const data = await req('POST', '/user/forgotPassword', {
              email: email.toLowerCase().trim(),
              device: {
                id: "web",
                deviceToken: "MockToken"
              }
            }, message => {
              setLoading(false)
              success = false
              setError(message)
            })
            if(!success) return
            setLoading(false)
            console.log(data)
            console.log("Tokens =>", data)
            navigate(`/verifyEmailForgetPassword?email=${encodeURIComponent(email)}`)
          }}>Verify</Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;

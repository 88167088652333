import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { calenderWithDots, filled, unFilled } from "../../../assets";
import { authReq } from "../../../requests";
import { getAllParams } from "../../../urlParams";
import { Button, SearchBar, TextInput, Loader } from "../../../components";
import { doGAPIAction, initCalendar } from "../appointments/Appointments";
import DropDown from "../../../components/DropDown/DropDown";
import moment from 'moment';
import "./rescheduleAppointment.css";

const createTimeSlots = (fromTime, toTime) => {
  let startTime = moment(fromTime, 'HH:mm');
  let endTime = moment(toTime, 'HH:mm');
  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day');
  }
  let arr = [];
  while (startTime <= endTime) {
    arr.push(new moment(startTime).format('HH:mm'));
    startTime.add(60, 'minutes');
  }
  return arr.map((startTime, index) => {
    return {
      label: `${startTime}`,
      id: index + 1
    };
  });
}

const baseArray = new Array(24).fill(0).map((_, i) => i+1 > 12 ? `${i+1-12}:00 PM` : `${i+1}:00 AM`).map(x => { return {label: x, value: x} })
console.log("Times", baseArray)

const RescheduleAppointment = () => {
  const params = getAllParams()
  const { id, free } = params
  const navigate = useNavigate();
  const [user, setUser] = useState({})
  const [date, setDate] = useState(new Date().toISOString().split('T')[0])
  const [reason, setReason] = useState("")
  const [loading, setLoading] = useState(true)
  const [duration, setDuration] = useState(free ? 15 : 45)
  const [doctorStartTime, setDoctorStartTime] = useState(0)
  const [doctorEndTime, setDoctorEndTime] = useState(0)
  const [appointments, setAppointments] = useState([])
  const [isSubmit, setIsSubmit] = useState(false);

  const [times, setTimes] = useState(baseArray)
  const [time, setSelectedTime] = useState({
    label: "",
    value: "",
  })

  const isDisabledTime = itemT => {
    if(!itemT.label) return true
    const properMin = free ? '45' : '00'
    const newItemT = { id: itemT.id, label: itemT.label.replace(':00', `:${properMin}`) }
    const dateTimeString = `${new Date(date).toISOString().split('T')[0]}T${newItemT.label}:00Z`
    const itemDate = new Date(dateTimeString)
    console.log("RXDATE", itemDate, new Date())
    if(itemDate.getTime() < Date.now()) return true
    if(parseInt(newItemT.label.split(':')[0]) < new Date().getHours() + 1 && new Date().toISOString().split('T')[0] == new Date(date).toISOString().split('T')[0]) return true
    const itemDateFound = appointments.find(app => {
      const appointmentStartDate = app.appointmentStart - new Date().getTimezoneOffset()*60_000
      return appointmentStartDate == itemDate.getTime()
    })
    console.log(newItemT, itemDateFound)
    return !!itemDateFound
  }

  const generateSlots = (item) => {
    let newDate = moment.utc(item?.endDateUTC).format('DD-MM-YYYY');
    let newDate22 = moment.utc(item?.endDateUTC).format('DD');
    const finalStartTime = moment.utc(item?.startDateUTC)
  
    let concateCurrentTime =
      moment.utc(newDate + ' ' + '00:00', 'DD-MM-YYYY HH:mm').unix() * 1000;
    let finalCurrentTime = moment.utc(concateCurrentTime).format('YYYY-MM-DD HH:mm')
    const start = finalStartTime;
    const end = moment(finalCurrentTime).subtract(newDate22 == finalStartTime.date() ? 0 : 1, 'day')
  
    const duration = moment.duration(end.diff(start));
    const hoursDiffBefore = duration.asHours() % 24;
  
    let newDate2 = moment.utc(item?.endDateUTC).format('DD-MM-YYYY');
  
  
    const finalEndTime = moment.utc(item?.endDateUTC)
    let concateCurrentTimeTwo =
      moment.utc(newDate2 + ' ' + '23:59', 'DD-MM-YYYY HH:mm').unix() * 1000;
    let finalCurrentTimeTwo = moment.utc(concateCurrentTimeTwo).format('YYYY-MM-DD HH:mm')
    const startTwo = moment(finalEndTime);
    const endTwo = moment(finalCurrentTimeTwo).subtract(newDate22 == finalStartTime.date() ? 0 : 1, 'day');
    const durationTwo = moment.duration(endTwo.diff(startTwo));
    const hoursDiffBeforeTwo = durationTwo.asHours() % 24;
  
  
    if (hoursDiffBefore > 0) {
      const date1 = moment.utc(item?.startDateUTC)
      var stillUtc1 = moment.utc(date1).toDate();
      var startTime = moment(stillUtc1).local().format('HH:mm');
      var timeTwo = moment(startTime, 'HH:mm');
      var addedTime = moment(timeTwo).add(hoursDiffBefore, 'hours');
      var startTime = addedTime.format('HH:mm');
      setDoctorStartTime(startTime)
      const date2 = moment.utc(item?.endDateUTC)
      var stillUtc2 = moment.utc(date2).toDate();
      var endTime = moment(stillUtc2).local().format('HH:mm');
      setDoctorEndTime(endTime)
      const timeSlotsUtc = createTimeSlots(startTime, endTime).filter(item => !isDisabledTime(item))
      setTimes(timeSlotsUtc)
      return timeSlotsUtc
      // if (timeSlotsUtc.length > 0) {
      //   navigation.navigate(routes.booking, {
      //     drId: item?._id,
      //     item: item,
      //     appointments: appointments[item?.id],
      //     type: 'pro',
      //     myAppointments: myAppointments,
      //     timeSlotsLocal: timeSlotsUtc,
      //   })
      // }
    }
    else if (hoursDiffBeforeTwo < 0) {
      const date1 = moment.utc(item?.startDateUTC)
      var stillUtc1 = moment.utc(date1).toDate();
      var startTime = moment(stillUtc1).local().format('HH:mm');
      setDoctorStartTime(startTime)
      const date2 = moment.utc(item?.endDateUTC)
      var stillUtc2 = moment.utc(date2).toDate();
      var endTime = moment(stillUtc2).local().format('HH:mm');
      const time = moment(endTime, 'HH:mm');
      const subtractedTime = moment(time).add(hoursDiffBeforeTwo, 'hours');
      var endTime = subtractedTime.format('HH:mm');
      setDoctorEndTime(endTime)
      const timeSlotsUtc = createTimeSlots(startTime, endTime).filter(item => !isDisabledTime(item))
  
      setTimes(timeSlotsUtc)
      return timeSlotsUtc
      // if (timeSlotsUtc.length > 0) {
      //   navigation.navigate(routes.booking, {
      //     drId: item?._id,
      //     item: item,
      //     appointments: appointments[item?.id],
      //     type: 'pro',
      //     myAppointments: myAppointments,
      //     timeSlotsLocal: timeSlotsUtc,
      //   })
      // }
    }
    else {
      const date1 = moment.utc(item?.startDateUTC)
      var stillUtc1 = moment.utc(date1).toDate();
      var startTime = moment(stillUtc1).local().format('HH:mm');
      setDoctorStartTime(startTime)
      const date2 = moment.utc(item?.endDateUTC)
      var stillUtc2 = moment.utc(date2).toDate();
      var endTime = moment(stillUtc2).local().format('HH:mm');
      setDoctorEndTime(endTime)
      const timeSlotsUtc = createTimeSlots(startTime, endTime).filter(item => !isDisabledTime(item))
      setTimes(timeSlotsUtc)
      return timeSlotsUtc
      // if (timeSlotsUtc.length > 0) {
      //   navigation.navigate(routes.booking, {
      //     drId: item?._id,
      //     item: item,
      //     appointments: appointments[item?.id],
      //     type: 'pro',
      //     myAppointments: myAppointments,
      //     timeSlotsLocal: timeSlotsUtc,
      //   })
      // }
    }
  }

  console.log(times)
  const formatTime = (date, time) => {
    const slot = `${time} ${date}`;
    return slot
  }

  useEffect(() => {
    authReq('GET', '/user/me')
      .then(data => setUser(data.data))
  }, [])

  useEffect(() => {
    authReq('GET', '/appointment?onlyTimes=true')
      .then(data => {
        setLoading(false)
        const singleAppointment = data.appointments.find(app => app._id == id)
        setAppointments(data.appointments.filter(x => x.status !== 'patient-canceled').filter(x => x.status !== 'patient-completed').map(app => {
          const startDate = new Date(app.appointmentStart).toISOString()
          const endDate = new Date(app.appointmentEnd).toISOString()
          console.log(startDate, endDate, app.status)
          return { ...app }
        }))
        const selectedTime = new Date(singleAppointment.appointmentStart - new Date().getTimezoneOffset() * 60_000).toISOString().split("T")[1].split(":").slice(0, 2).join(":")
        console.log("SELECTIME", selectedTime)
        setDate(new Date(singleAppointment.appointmentStart).toISOString().split("T")[0])
        setSelectedTime({
          label: selectedTime,
          value: selectedTime
        })
      })
  }, [])

  useEffect(() => {
    console.log("Ummmm")
    const existsArr = appointments.filter(app => app.slot === formatTime(date, time))
    console.log(appointments)
    if(existsArr.length > 0) console.log("Exists")
  }, [date, time])

  useEffect(() => {
    generateSlots(user)
  }, [date, user, appointments])
  return (
    <div className="kavan_admin_main_container">
      {loading && <Loader/>}
      <div className="kwn-search">
        <SearchBar />
      </div>
      {isSubmit ? (
        <div className="kwn-reschedule_appointment_success_top_view">
          <div className="kwn-reschedule_appointment_success_view">
            <img src={calenderWithDots} />
            <h1>Rescheduling Success!</h1>
            <h3>
              Appointment succesfully changed. You will receive a notification
              and the patient you selected will contact you.
            </h3>
            <div className="kwn-reschedule_appointment_appointment_button">
              <Button onClick={() => navigate(-1)} style={{ width: 214 }}>
                View Appointment
              </Button>
            </div>
            <div className="kwn-reschedule_appointment_cancel_button">
              <Button
                onClick={() => navigate(-1)}
                border
                style={{ width: 214 }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <h1 className="kwn-heading">Cambiar Cita </h1>
          <div className="kwn_reschedule_appointment_reasons_top_view">
            <h2>Motivo del cambio de la cita</h2>
            <div className="kwn_reschedule_appointment_reasons_view">
              {/* {reasonArray.map((item) => {
                return (
                  <div
                    onClick={() => setSelected(item)}
                    className="kwn_reschedule_appointment_reasons"
                  >
                    <img src={item.id === selected.id ? filled : unFilled} />
                    <h2>{item.title}</h2>
                  </div>
                );
              })} */}
              <TextInput
                type={"text"}
                value={reason}
                onChange={ev => setReason(ev.target.value)}
                inputStyle={{ fontSize: 20 }}
                style={{ width: "97%" }}
                placeholder={"Me ha surgido un imprevisto personal..."}
              />
            </div>
            <div className="kwn_reschedule_appointment-nueva">
              <h1>Nueva Cita</h1>
            </div>
            <div className="kwn_reschedule_appointment_inputs_top_view">
              <TextInput
                type={"date"}
                value={date}
                onChange={ev => {
                  setDate(ev.target.value)
                  setSelectedTime({})
                  // setTimeout(() => {
                  //   setSelectedTime({
                  //     label: times[0]?.label?.replace(':00', free ? ':45' : ':00'),
                  //     value: times[0]?.label?.replace(':00', free ? ':45' : ':00'),
                  //   })
                  // }, 500)
                }}
                inputStyle={{ fontSize: 20 }}
                style={{ width: "100%" }}
                title={"Select Date"}
              />
              <DropDown
                text={"Time"}
                bg={times.length == 0 ? {
                  opacity: "0.4",
                } : {}}
                options={times.map(time => free ? ({ label: time.label.replace(':00', ':45'), value: time.label.replace(':00', ':45') }) : time)}
                selected={time?.label ? time : { label: times[0]?.label?.replace(':00', free ? ':45' : ':00'), value: times[0]?.label?.replace(':00', free ? ':45' : ':00') }}
                setSelected={setSelectedTime}
              />
              {times.length == 0 && <p style={{ color: "red", marginLeft: "1rem" }}>No timeslots available on {new Date(date).toDateString()}</p>}
              {/* <TextInput
                type={"number"}
                value={duration}
                onChange={ev => setDuration(ev.target.value)}
                placeholder={"30"}
                inputStyle={{ fontSize: 20 }}
                style={{ width: "100%" }}
                title={"Select Duration (Mins)"}
              /> */}
            </div>
            <div className="kwn_reschedule_appointment_button_view">
              <Button disabled={(!date) || (!time)} onClick={async () => {
                const properMin = free ? '45' : '00'
                const dateTimeString = `${new Date(date).toISOString().split('T')[0]}T${time.label.replace(':00', `:${properMin}`)}:00Z`
                const appointmentStart = new Date(dateTimeString).getTime() + new Date().getTimezoneOffset()*60_000
                const appointmentEnd = appointmentStart + (duration * 60_000)
                const newSlot = formatTime(date, time.label)
                console.log("CALLSTRING", dateTimeString, newSlot)

                setLoading(true)
                console.log(params)
                await authReq('PATCH', `/appointment/${params.id}`, {
                  status: 'patient-reschedule',
                  rescheduleAppointmentStart: appointmentStart,
                  rescheduleAppointmentEnd: appointmentEnd,
                  rescheduleRequestedBy: user?._id,
                  slot: newSlot,
                  cancelationReason: reason
                })
                doGAPIAction(async gapi => {
                  const appointment = appointments.find(app => app._id == params.id)
                  const calendarId = await initCalendar()
                  if(appointment.eventId) await gapi.client.calendar.events.update({
                    calendarId,
                    eventId: appointment.eventId,
                    resource: {
                      summary: appointment.patient?.name ? `Appointment with ${appointment.patient?.name}` : "Appointment",
                      start: {
                        dateTime: new Date(appointmentStart).toISOString(),
                        timeZone: "UTC",
                      },
                      end: {
                        dateTime: new Date(appointmentEnd).toISOString(),
                        timeZone: "UTC",
                      }
                    },
                  })
                  else await gapi.client.calendar.events.insert({
                    calendarId,
                    resource: {
                      summary: appointment.patient?.name ? `Appointment with ${appointment.patient?.name}` : "Appointment",
                      start: {
                        dateTime: new Date(appointmentStart - new Date().getTimezoneOffset()*60_000).toISOString(),
                        timeZone: "UTC",
                      },
                      end: {
                        dateTime: new Date(appointmentEnd - new Date().getTimezoneOffset()*60_000).toISOString(),
                        timeZone: "UTC",
                      }
                    },
                    conferenceDataVersion: 1,
                  })
                })
                setLoading(false)

                setIsSubmit(true)
              }}>Submit</Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RescheduleAppointment;

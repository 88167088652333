import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  chatLogo,
  close,
  dummy,
  dummy2,
  dummy3,
  editSquare,
  menu,
  mic,
  optionsIcon,
  send,
  user,
  video,
} from "../../../assets";
import { SearchBar, Loader } from "../../../components";
import { baseURL } from "../../../constants";
import { authReq, uploadAudioOnS3 } from "../../../requests";
import socketIO from "socket.io-client";

import "./chat.css";
import { AudioRecorder } from "react-audio-voice-recorder";
import { addDoc, collection, doc, getDocs, onSnapshot, or, query, updateDoc, where } from "firebase/firestore";
import { fireDB } from "../../../firebase";
import { ref } from "firebase/storage";

const socket = socketIO(baseURL);

const scrollToLastMessage = () => {
  // const messageEls = document.getElementsByClassName("kwn-chat-chating_item_view")
  // messageEls[messageEls.length - 1]?.scrollIntoView()
}

const formatDate = date => {
  console.log("Formatting Date .......", date)
  return `${date.getDate()}/${date.getMonth() + 1}/${`${date.getFullYear()}`.at(-2)}${`${date.getFullYear()}`.at(-1)} ${date.getHours()}:${date.getMinutes()}`
}

const zip = (as, bs) => as.map((x, i) => [x, bs[i]]).slice(0, Math.max(as.length, bs.length))

const Chat = (props) => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  const [selectedChat, setSelectedChat] = useState({});

  const [message, setMessage] = useState("");
  const [currAudio, setCurrAudio] = useState("")
  const [chatArray, setChatArray] = useState([]);
  const [messages, setMessages] = useState([])
  const [inboxes, setInboxes] = useState([])
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);


  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([])
  const onSearch = (text) => {
    setSearchText(text)
    setSearchData(inboxes.filter(x => x.name.toLowerCase().startsWith(text.toLowerCase())))

  }

  const addAudioElement = (blob) => uploadAudioOnS3(blob).then(url => setCurrAudio(url))

  useEffect(() => {
    props.setActive("Chat")
    authReq("GET", "/user/me").then((data) => setUser(data.data));
  }, []);

  useEffect(() => {
    console.log("meeeeeessssssages000", user)
    if (!user?._id) return;
    const f = async () => {
      const q = query(collection(fireDB, 'messages'), or(
        where('sender._id', '==', user?._id),
        where('receiver._id', '==', user?._id),
      ))
      console.log("meeeeeessssssages111", messages)
      onSnapshot(q, async snapshot => {
        const messages = snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })).sort((a, b) => (a?.createdAt ?? a?.messageTime) > (b?.createdAt ?? b?.messageTime) ? 1 : -1)
        const selectedChatMessages = messages.filter(x => x?.sender?._id == selectedChat?._id || x?.receiver?._id == selectedChat?._id)
        for (const message of selectedChatMessages) {
          console.log("slectechst", selectedChatMessages)
          const ref = doc(fireDB, 'messages', message.uid)
          updateDoc(ref, { readByDoctor: true })
        }
        console.log("meeeeeessssssages", messages)
        setMessages(messages)
        const users = {}
        const userLastMessages = {}
        const userMessageCounts = {}
        for (const message of messages) {
          userMessageCounts[message?.sender?._id] = 0
          userMessageCounts[message?.receiver?._id] = 0
        }
        for (const message of messages) {
          if (message?.sender) users[message?.sender?._id] = message?.sender
          if (message?.receiver) users[message?.receiver?._id] = message?.receiver
          if (message?.sender) userLastMessages[message?.sender?._id] = message
          if (message?.receiver) userLastMessages[message?.receiver?._id] = message
          if (message?.sender && !message?.readByDoctor) userMessageCounts[message?.sender?._id] += 1
          if (message?.receiver && !message?.readByDoctor) userMessageCounts[message?.receiver?._id] += 1
        }
        delete users[user?._id]
        delete userLastMessages[user?._id]
        delete userMessageCounts[user?._id]

        const inboxes = Object.values(users).map(u => ({
          ...u,
          unreadMessageCount: selectedChat?._id == u?._id ? 0 : userMessageCounts?.[u?._id],
          message: userLastMessages[u?._id]?.message
        }))
        setInboxes(inboxes)
        setLoading(false)
        setTimeout(() => scrollToLastMessage(), 1000);
      })
    }
    f()
  }, [user])

  useEffect(() => {
    if (!selectedChat?._id) return;
    const selectedChatMessages = messages.filter(x => x?.sender?._id == selectedChat?._id || x?.receiver?._id == selectedChat?._id)
    for (const message of selectedChatMessages) {
      const ref = doc(fireDB, 'messages', message.uid)
      updateDoc(ref, { readByDoctor: true })
    }
    scrollToLastMessage()
  }, [selectedChat])

  const sendMessage = async () => {

    // Send a message

    const el = document.getElementById(selectedChat.id)
    if (el) el.innerHTML = message

    const obj = {
      createdAt: new Date().getTime(),
      message: currAudio ? currAudio : message,
      messageTime: new Date().getTime(),
      receiver: {
        _id: selectedChat?._id,
        name: selectedChat?.name,
        image: selectedChat?.image,
      },
      readByDoctor: true,
      sender: {
        _id: user?._id,
        name: user?.name,
        image: user?.image
      },
      messageType: currAudio ? "audio" : "text",
      updatedAt: "2023-05-05T12:34:36.748Z",
      // _id: `${Math.round(Math.random() * 10000)}`,
    }
    setMessages([
      ...messages,
      obj,
    ]);
    setMessage("");
    setCurrAudio("")

    await addDoc(collection(fireDB, 'messages'), obj)
  };

  return !messages.length ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",

        width: "100%",
      }}
      className="kavan_admin_main_container"
    >
      {loading && <Loader />}
      <h1>You have no appointments yet</h1>
    </div>
  ) : (
    <div style={{ paddingLeft: "15%", paddingRight: "15%" }} className="kavan_admin_main_container">
      {loading && <Loader />}
      <div className="kwn-search" style={{ paddingBottom: 0 }}>
        <SearchBar visible={true} setSearchText={onSearch} searchText={searchText} placeholder={"buscar chats"} />
      </div>
      <div className="kwn-chat-main_view">
        <div
          className={
            toggle
              ? "kwn-chat-main-header-box-active"
              : "kwn-chat-main-header-box-hide"
          }
        >
          <HeradrBox
            user={user}
            inboxes={inboxes}
            setInboxes={setInboxes}
            setChatArray={setChatArray}
            chatArray={chatArray}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            setToggle={setToggle}
            toggle={toggle}
            socket={socket}
          />
        </div>

        <div className="kwn-chat-inbox_view_divider" />

        <div className="kwn-chat-message_top_view">
          <div className="kwn-chat-message_view_header">
            <div className="kwn-chat-message_view_header_title">
              <div
                onClick={() => setToggle(!toggle)}
                className="kwn-chat-toggle-container"
              >
                <img src={menu} alt="" />
              </div>
              <img src={selectedChat.image} />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2>{selectedChat.name}</h2>
                {/* <h3>Active now</h3> */}
              </div>
            </div>
            <div className="kwn-chat-message_view_header_icons">
              {/* <img
                className="kwn-chat-message_view_header_more_icon"
                src={optionsIcon}
              />
              <img
                onClick={() => navigate("sessionEnded")}
                className="kwn-chat-message_view_header_video_icon"
                src={video}
              /> */}
            </div>
          </div>
          <div className="kwn-chat-messages-view">
            <div className="kwn-chat-chating_view">
              <div id="chatbox" className="kwn-chat-chating_item_top_viwe">
                {messages
                  .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
                  .filter(x => x?.sender?._id == selectedChat?._id || x?.receiver?._id == selectedChat?._id)
                  .map((item) => {
                    return (
                      <div
                        className="kwn-chat-chating_item_view"
                        style={{
                          alignSelf:
                            item.sender?._id === user._id
                              ? "flex-end"
                              : "flex-start",
                        }}
                      >
                        {item.sender?._id === user._id && <img src={chatLogo} />}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor:
                              item.sender?._id === user._id ? "#FFFFFF" : "#006039",
                            borderTopLeftRadius:
                              item.sender?._id === user._id ? 0 : 24,
                            borderBottomRightRadius:
                              item.sender?._id === user._id ? 24 : 0,
                          }}
                        >
                          {item?.messageType == "audio" || item?.type == "audio" ? <audio controls>
                            <source src={item?.message} type="audio/webm" />
                          </audio> : <h3
                            style={{
                              color:
                                item.sender?._id === user._id
                                  ? "#666666"
                                  : "#FFFFFF",
                            }}
                          >
                            {item.message}
                          </h3>}
                          <p style={{ alignSelf: "flex-end", color: "darkgray" }}>{[[console.log(item.messageTime, item.createdAt)], formatDate(new Date(item.createdAt))][1]}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            {selectedChat.default ? (
              <></>
            ) : (
              <div className="kwn-chat-send_view">
                <div className="kwn-chat-send-input_view">
                  {currAudio ? <audio controls>
                    <source src={currAudio} type="audio/webm" />
                  </audio> : <input
                    placeholder="Escribe un mensaje..."
                    value={message}
                    onChange={(ev) => setMessage(ev.target.value)}
                    onKeyDown={(ev) => {
                      if (ev.key === "Enter") sendMessage();
                    }}
                  />}
                  {/* <img src={mic} style={{ cursor: "pointer" }} /> */}
                </div>
                <AudioRecorder
                  onRecordingComplete={addAudioElement}
                  audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                    // autoGainControl,
                    // channelCount,
                    // deviceId,
                    // groupId,
                    // sampleRate,
                    // sampleSize,
                  }}
                  onNotAllowedOrFound={(err) => console.table(err)}
                  downloadOnSavePress={true}
                  downloadFileExtension="webm"
                  mediaRecorderOptions={{
                    audioBitsPerSecond: 128000,
                  }}
                // showVisualizer={true}
                />
                <div className="kwn-chat-send_button_view">
                  <img
                    id="send-message"
                    src={send}
                    style={{ cursor: "pointer" }}
                    onClick={() => sendMessage()}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const HeradrBox = ({
  user,
  setChatArray,
  chatArray,
  selectedChat,
  setSelectedChat,
  setToggle,
  toggle,
  socket,
  inboxes
}) => {
  return (
    <div className="kwn-chat-inbox_top_view">
      <div className="kwn-chat-inbox_view_header">
        <h2>Mensajería</h2>
        <div>
          {/* <img src={optionsIcon} />
          <img src={editSquare} /> */}
        </div>
      </div>
      {console.log('iiiiiinboxes', inboxes)}
      <div style={{ background: "#f5f5f5" }} className="kwn-chat-inbox_view">
        {inboxes.sort((a, b) => a.lastMessageTime > b.lastMessageTime ? -1 : 1).map((item) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                background: item._id === selectedChat._id ? "rgba(0, 96, 57, 0.4)" : undefined,
                borderRadius: '15px',
                padding: '5px',
              }}
              onClick={() => setSelectedChat(item)}
            >
              <img src={item.image} style={{ height: '50px', clipPath: 'circle()' }} />
              <div style={{ marginLeft: '20px' }}>
                <h2
                  style={{
                    fontFamily: 'var(--font-family)',
                    color: item._id === selectedChat._id ? "#FFFFFF" : "black",
                  }}
                >
                  {item.name} {item.unreadMessageCount ? `(${item.unreadMessageCount})` : ""}
                </h2>
                <h3
                  id={item._id}
                  style={{
                    color: item._id === selectedChat._id ? "#F5F5F5" : "#666666",
                  }}
                >
                  {item?.message?.messageType == "audio" ? "Audio" : item.message?.text}
                </h3>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Chat;

import React from "react";
import { Routes, Route } from "react-router-dom";
import * as Admin from "../../pages/admin";

const PacientsStack = (props) => {
  return (
    <Routes>
      <Route path="/" element={<Admin.Pacients setActive={props.setActive} />} />

    </Routes>
  );
};

export default PacientsStack;

import React, { useState } from "react";
import { apple, blackCheck, facebookBlue, clockWithDots } from "../../../assets";
import { getAllParams } from "../../../urlParams";
import { Navbar, Footer, TextInput, Button } from "../../../components";
import DropDown from "../../../components/DropDown/DropDown";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { authReq, req } from "../../../requests";
import { useNavigate } from "react-router-dom";
import "./patientForm.css";

const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

const Success = () => {
  
    return (
      <div>
        <Navbar />
        <div className="kwn-fill_out_form-main_container">
          <div className="kwn-fill_out_form-title_container">
            <div className="kwn-reg_pending-title_container">
              <img alt="" src={clockWithDots} />
            </div>
            <h1>Su solicitud ha sido enviada</h1>
            <h2>Tus datos han sido enviados al médico.</h2>
          </div>
          {/* <Button onClick={() => navigate("/registrationSuccessfull")}>
            Next
          </Button> */}
          <div
            onClick={() => window.location.href = "https://www.kavanhealth.com/log-in"}
            className="kwn-create_profile-add_later"
          >
          </div>
        </div>
        <Footer />
      </div>
    );
};
  

export default function SignUp() {
  const navigate = useNavigate();
  const params = getAllParams()
  const [name, setName] = useState("")
  const [email, setEmail] = useState(params.email)
  const [userInfo, setTrueUserInfo] = useState({})
  const [emailConfirmationMessage, setEmailConfirmationMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [nameConfirmationMessage, setNameConfirmationMessage] = useState("")
  const [error, setError] = useState("")

  const setUserInfo = (k, v) => setTrueUserInfo({ ...userInfo, ...{[k]: v} })

  return success ? <Success/> : (
    <div>
      <Navbar />
      <div className="kwn-sign_up-main_container">
        <div className="kwn-sign_up-title_container">
          <h1>Información adicional para el psicólogo</h1>
          <h2>Regístrese para empezar a utilizar Kavan Health</h2>
        </div>

        <div className="kwn-sign_up-form_container">
          <TextInput
            type={"text"}
            title={"Email"}
            disabled={true}
            value={email}
            onChange={(ev) => {
              console.log("NOW, THIS IS", ev.target.value, ev.target.value.length == 0)
            //   setEmail(ev.target.value)
              if(ev.target.value.length == 0) setEmailConfirmationMessage("")
              else if(!ev.target.value.includes("@")) setEmailConfirmationMessage("Invalid email")
              else setEmailConfirmationMessage("")
            }}
            placeholder={"Email"}
          />
          <TextInput
            type={"text"}
            title={"Edad"}
            value={userInfo['edad']}
            onChange={(ev) => {
              setUserInfo('edad', ev.target.value.split("").filter(x => "1234567890".includes(x)).join(""))
            }}
            placeholder={"24"}
          />
          <TextInput
            type={"text"}
            title={"Nombre completo"}       
            value={userInfo['nombreCompleto']}
            onChange={(ev) => {
              setUserInfo('nombreCompleto', ev.target.value)
            }}
          />
          <TextInput
            type={"date"}
            title={"Fecha De nacimiento"}
            value={userInfo['fechaDenacimiento']}
            onChange={(ev) => {
              setUserInfo('fechaDenacimiento', ev.target.value)
            }}
            placeholder={"Fecha De nacimiento"}
          />
          {/* <TextInput
            type={"text"}
            title={"Sexo"}
            value={userInfo['sexo']}
            onChange={(ev) => {
              setUserInfo('sexo', ev.target.value)
            }}
            placeholder={"Hombre"}
          /> */}
          <DropDown
            text={"Sexo"}
            options={["Hombre", "Mujer", "Prefiero no decirlo"].map(x => ({label: x, value: x}))}
            selected={{label: userInfo['sexo'], value: userInfo['sexo']}}
            setSelected={v => setUserInfo('sexo', v.value)}
          />
          <TextInput
            type={"text"}
            title={"DNI/CUIL"}            
            value={userInfo['dni']}
            onChange={(ev) => {
              setUserInfo('dni', ev.target.value)
            }}
          />
          <TextInput
            type={"text"}
            title={"Nacionalidad"}            
            value={userInfo['nacionalidad']}
            onChange={(ev) => {
              setUserInfo('nacionalidad', ev.target.value)
            }}
          />
          <TextInput
            type={"text"}
            title={"Domicilio"}
            value={userInfo['domicilio']}
            onChange={(ev) => {
              setUserInfo('domicilio', ev.target.value)
            }}
          />
          <TextInput
            type={"text"}
            title={"Estado civil"}
            value={userInfo['estadoCivil']}
            onChange={(ev) => {
              setUserInfo('estadoCivil', ev.target.value)
            }}
          />
          <TextInput
            type={"text"}
            title={"Actividad laboral"}
            value={userInfo['actividadLaboral']}
            onChange={(ev) => {
              setUserInfo('actividadLaboral', ev.target.value)
            }}
          />
         <TextInput
            type={"text"}
            title={"Tratamientos previos y/o paralelos"}
            value={userInfo['paralelos']}
            onChange={(ev) => {
              setUserInfo('tratamientosParalelos', ev.target.value)
            }}
          />
          <TextInput
            type={"text"}
            title={"Composición familiar (con edad de los miembros)"}
            value={userInfo['ComposiciónFamiliar']}
            onChange={(ev) => {
              setUserInfo('ComposiciónFamiliar', ev.target.value)
            }}
          />
          <TextInput
            type={"text"}
            title={"Antecedentes heredofamiliares psicologicos y/o psiquiatricos"}
            value={userInfo['antecedentesHeredofamiliaresPsicologicos']}
            onChange={(ev) => {
              setUserInfo('antecedentesHeredofamiliaresPsicologicos', ev.target.value)
            }}
          />
          <TextInput
            type={"text"}
            title={"Enfermedades clínicas"}
            value={userInfo['enfermedadesClínicas ']}
            onChange={(ev) => {
              setUserInfo('enfermedadesClínicas ', ev.target.value)
            }}
          />
          <TextInput
            type={"text"}
            title={"¿Toma algún tipo de medicación? ¿Cuál?"}
            value={userInfo['medication']}
            onChange={(ev) => {
              setUserInfo('medication', ev.target.value)
            }}
          />
        </div>

            
        <div className="kwn-sign_up-Button_container">
          <Button
            disabled={!email || !email.includes("@")}
            onClick={async () => {
              let success = true;
              setLoading(true);
              await authReq(
                "PATCH",
                "/user/updateProfileNoAuth",
                {
                  email: email.toLowerCase().trim(),
                  userInfo,
                //   password,
                //   doctor: true,
                },
                (message) => {
                  setLoading(false);
                  setError(message);
                  success = false;
                }
              );
              if (!success) return;
              setLoading(false)
              setSuccess(true)
            //   await req("POST", "/user/sendOTP", { email: email.toLowerCase().trim() });
            //   navigate(`/verifyEmail?email=${encodeURIComponent(email)}`);
            }}
          >
            {loading ? "Loading..." : "Enviar"}
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import { initializeApp } from "firebase/app";
// import * as auth from "firebase/auth";
import * as dbModule from "firebase/firestore";
import * as storageModule from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBfY1EZciQ92GY_0Sx9yEaXPEKjRw2U2XI",
    authDomain: "kawan-b2148.firebaseapp.com",
    databaseURL: "https://kawan-b2148-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "kawan-b2148",
    storageBucket: "kawan-b2148.appspot.com",
    messagingSenderId: "322067737665",
    appId: "1:322067737665:web:ce4059a2733de4dda8eac0",
    measurementId: "G-JQ8K63MTE1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = dbModule.getFirestore(app);
const storage = storageModule.getStorage(app);

const fireDB = db
const fireStorage = storage

export {
    fireDB,
    fireStorage,
}
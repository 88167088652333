import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { clockWithDots } from "../../../assets";
import { Button, Footer, Navbar } from "../../../components";
import { getAllParams } from "../../../urlParams";
import { authReq } from "../../../requests";
import "./registrationPending.css";
const RegistrationPending = () => {
  useEffect(() => {
    const obj = JSON.parse(JSON.stringify(getAllParams()));
    const keys = Object.keys(obj);
    console.log("OBJECT =>", obj);
    if (keys.length > 0)
      authReq("PATCH", "/user/updateProfile", obj).then((_) => console.log(_));
  }, []);

  const navigate = useNavigate();
  return (
    <div>
      <Navbar />
      <div className="kwn-fill_out_form-main_container">
        <div className="kwn-fill_out_form-title_container">
          <div className="kwn-reg_pending-title_container">
            {/* <img alt="" src={clockWithDots} /> */}
          </div>
          <h1>Estamos verificando su cuenta</h1>
          <h2>Una vez que un administrador haya revisado su perfil, podrá tener acceso</h2>
        </div>
        {/* <Button onClick={() => navigate("/registrationSuccessfull")}>
          Next
        </Button> */}
        <div
          onClick={() => window.location.href = "https://www.kavanhealth.com/log-in"}
          className="kwn-create_profile-add_later"
        >
          {/* <Button> */}
          <p style={{ textDecoration: 'underline', fontSize: 16 }} onClick={() => navigate('/dashboard/AppointmentStack')}>
            Volver a ianiciar sesion
          </p>
          {/* </Button> */}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default RegistrationPending;

import React from 'react'
import { search } from '../../assets'
import './searchBar.css'
const SearchBar = (props) => {
    if (!props?.visible) {
        return null;
    }
    return (
        <div className='kwn_search_bar_container'>
            <div>
                <img src={search} />
                <input value={props?.searchText} onChange={(data) => { props.setSearchText(data.target.value) }} placeholder={props?.placeholder ?? 'Search'} />
            </div>
        </div>
        // <></>
    )
}

export default SearchBar

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Footer, Navbar } from "../../../components";
import { getAllParams, setParam } from "../../../urlParams";
import { chatbotStarIcon, chatbotLogo, chatbot1, chatbot2, chatbot3, chatbot4 } from "../../../assets";

import "./chatbot.css"
import { useMetaPixel } from "../../../metaPixel";

const ChooseProfile = () => {
  const navigate = useNavigate();
  const { state: { doctors, reviews } } = useLocation()
  const [doctorList] = useState(doctors?.slice(0, 3))
  const pixel = useMetaPixel()

  console.log("reviews", doctorList)

  const redirectToApp = () => {
    pixel.trackCustom('RedirectToApp')
    window.open("https://play.google.com/store/apps/details?id=com.kavan&hl=en_US", "_blank")
  }

  return (
    <div className="chatbot-container-scrollable">
        <div className="chatbot-start-main">
            <div className="chatbot-heading">
                <img src={chatbotLogo}/>
                <h1>KAVAN</h1>
            </div>
            <div className="chatbot-desc">
                <p>Estos son los psicólogos recomendados para ti. Para poder reservar una sesión descargate la app gratis y haz log in. </p>
            </div>
            <div className="chatbot-main-list-container">
                {
                    doctorList?.map(({_id, name, image, doctorType}) => <div className="chatbot-doctor-card">
                        <div className="card-top-part">
                            <img src={image ?? "https://pdtxar.com/wp-content/uploads/2019/04/person-placeholder.jpg"} />
                            <div className="card-desc-part">
                                <h1>{name}</h1>
                                <h2>Doctor {doctorType}</h2>
                                <div className="rating-container">
                                    <img src={chatbotStarIcon}/>
                                    <p>{((reviews?.[_id]?.map(r => r.rating)?.reduce((a, b) => a+b, 0) ?? 0)/(reviews?.[_id]?.length ?? 1)).toFixed(2)} ({reviews?.[_id]?.length ?? 0} Reviews)</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-bottom-part">
                            <p onClick={redirectToApp} style={{ borderColor: "" }}>Leer Bio</p>
                            <p onClick={redirectToApp} style={{ borderColor: "rgba(0, 0, 0, 0)", backgroundColor: "#006039", color: "white" }}>Conocernos <br/> 15 min sin coste</p>
                            <p onClick={redirectToApp} style={{ borderColor: "rgba(0, 0, 0, 0)", backgroundColor: "#006039", color: "white" }}>Reservar</p>
                        </div>
                    </div>)
                }
            </div>
            <div className="chatbot-main-list-container">
                <div className="chatbot-main-list-item">
                    <img src={chatbot4}/>
                    <p style={{ fontSize: "15px" }}>Nuestra función es filtrar las necesidades del usuario y ponerlo en contacto con los mejores profesionales para que lo atiendan. <br/> Nuestras conversaciones son privadas, las analizamos como herramienta de búsqueda para encontrar al profesional que el usuario necesita.</p>
                </div>
            </div>
            <div className="chatbot-main-button" onClick={redirectToApp}>
                <p>Descargar app</p>
            </div>
        </div>
    </div>
  );
};

export default ChooseProfile;

import React, { useEffect, useState, useRef } from 'react';
import './style.css';
import { authReq } from '../../requests';
import { bookingGrey, calenderIcon, chat, chatGrey, kavanLogo, profile, profileGrey, stripe } from '../../assets';
import { useNavigate } from 'react-router-dom';
import { Loader } from '..';

export const loginSet = (kawan_accessToken, kawan_refreshToken) => {
    document.cookie = `kawan_accessToken=${kawan_accessToken};path=/;domain=.kavanhealth.com`
}

const TopNavbar = (props) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (localStorage.getItem('user')) {
            setUser(JSON.parse(localStorage.getItem('user')));
        } else {
            authReq("GET", "/user/me").then((data) => {
                localStorage.setItem('user', JSON.stringify(data));
                setUser(data);
            });
        }
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = async () => {
        setDropdownOpen(false)
        // Implement logout logic here
        console.log('Logout clicked');
        loginSet("")
        await authReq("POST", "/user/logout", {
            device: { id: "web", deviceToken: "MockToken" },
        });
        localStorage.clear();
        window.location.href = "https://www.kavanhealth.com/";
    };


    const dropDownNavigate = (path) => {
        setDropdownOpen(false)
        navigate(path)
    }


    const getUrl = () => {
        setIsLoading(true)
        authReq('GET', "/user/stripeLogin")
            .then(data => {
                setIsLoading(false)
                const url = data?.link?.url
                window.location.href = url;

                // console.log(user?.country)
                //   console.log("DATA LOGIN", data)
                //   if(data && !optionsArray.find(opt => opt.title == "STRIPE")) setOptionsArray([...optionsArray, user?.country?.toLowerCase() == "argentina" ? {} : {
                //     id: 6,
                //     title: "STRIPE",
                //     icon: "",
                //     route: false,
                //     link: url,
                //     stripe: true
                //   }])
            })
    }

    return (
        <nav className="navbar">
            <div className="kavan__navbar-links_logo">
                <img onClick={() => window.location.href = "https://www.kavanhealth.com"} src={kavanLogo} alt="Logo" />
            </div>
            <div className="nav-links">
                <div onClick={() => { navigate("/dashboard/AppointmentStack/") }} className='menu-box'>
                    <img className='menu-icon' src={props?.active == "calendar" ? calenderIcon : bookingGrey} alt="Agenda" />
                    <a style={{ color: props?.active == "calendar" ? "#006039" : "#9B9B9B" }} className='menu-text' >Calendario</a>
                </div>
                <div onClick={() => { navigate("/dashboard/PacientsStack/") }} className='menu-box'>
                    <img className='menu-icon' src={props?.active == "Pacients" ? profile : profileGrey} alt="Agenda" />
                    <a style={{ color: props?.active == "Pacients" ? "#006039" : "#9B9B9B" }} className='menu-text' >Pacientes</a>
                </div>
                <div onClick={() => { navigate("/dashboard/ChatStack/") }} className='menu-box'>
                    <img className='menu-icon' src={props?.active == "Chat" ? chat : chatGrey} alt="Agenda" />
                    <a style={{ color: props?.active == "Chat" ? "#006039" : "#9B9B9B" }} className='menu-text' >Mensajes</a>
                </div>

                {
                    user?.data?.country?.toLowerCase() != "argentina" &&
                    <div onClick={() => { getUrl() }} id="stripe" className='menu-box'>
                        {/* <img className='menu-icon' src={stripe} alt="Agenda" /> */}
                        <a className='menu-text' >Stripe</a>
                    </div>
                }

            </div>
            <div className="profile-icon">
                <img src={user?.data?.image} alt="Profile" onClick={toggleDropdown} />
                {dropdownOpen && (
                    <div ref={dropdownRef} className="dropdown-menu">
                        <a onClick={() => { dropDownNavigate("/dashboard/SettingStack/profile") }} >Profile</a>
                        {/* <a onClick={() => { dropDownNavigate("/dashboard/SettingStack/") }} >Settings</a> */}
                        <a onClick={handleLogout}>Logout</a>
                    </div>
                )}
            </div>
            {
                isLoading &&
                <Loader />
            }
        </nav >
    );
};

export default TopNavbar;

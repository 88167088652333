import React, { useRef } from "react";
import "./homePage.css";
import { Navbar, Button, Footer } from "../../../components";
import {
  home_headerImg,
  dummy2,
  dummy3,
  dummy1,
  therapyIcon,
  star,
  appleStore,
  googleStore,
  climb,
  dots,
  direction,
  option,
  home,
  homeImage1,
  playStore,
  appleStore2,
  comparison,
  wave,
  mobileSS,
  homeIcon1,
  homeIcon2,
  homeIcon3,
  homeIcon4,
  homeIcon5,
  homeEllipse,
  hr1,
  hr2,
} from "../../../assets";
import Testimonial from "./comp/Testimonial";
import { red } from "@mui/material/colors";
import AppDownload from "../../../components/appDownload/appDownload";
import ReactButton from "../../../components/rectButton/reactButton";
import FooterPublic from "../../../components/footerPublic/footerPublic";

export default function HomePage() {
  const testimonialArray = [
    {
      id: 1,
      text: "Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    },
    {
      id: 2,
      text: "Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    },
    {
      id: 3,
      text: "Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    },
    {
      id: 4,
      text: "Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    },
    {
      id: 5,
      text: "Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    },
  ];
  const psychologistArray = [
    {
      id: 1,
      title:
        "Kavan Helps the psychologist coordinate easily with thier patients.",
    },
    {
      id: 2,
      title: "Easy Time Managment with the Online sessions.",
    },
    {
      id: 3,
      title: "Effectively streamline communication with patients.",
    },
  ];

  const availablePatientsArray = [
    {
      id: 1,
      name: "Dr. Atiana",
      img: dummy3,
      profession: "General Psychologist specialist",
      reviews: "540 Reviews (4.5 +)",
    },
    {
      id: 2,
      name: "Dr. Atiana",
      img: dummy2,
      profession: "General Psychologist specialist",
      reviews: "540 Reviews (4.5 +)",
    },
    {
      id: 3,
      name: "Dr. Atiana",
      img: dummy3,
      profession: "General Psychologist specialist",
      reviews: "540 Reviews (4.5 +)",
    },
  ];

  const iconArray = [
    {
      id: 1,
      title: "Empieza una sesión en 25 minutos",
      icon: homeIcon1,
    },
    {
      id: 2,
      title: "Sesiones personalizadas desde ",
      icon: homeIcon2,
    },
    {
      id: 3,
      title: "Matcheo con el profesional que se adapta a ti",
      icon: homeIcon3,
    },
    {
      id: 4,
      title: "Más de 50 profesionales disponibles",
      icon: homeIcon4,
    },
    {
      id: 5,
      title: "Desde la comodidad de tu casa",
      icon: homeIcon5,
    },
  ];

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();


  window.location.href = "https://www.kavanhealth.com/"
  return <></>

  return (
    <div>
      <div className="home-view">
        {/* <div className="kawan-therapy_all-container">
          <h1>Therapy for all </h1>
          <div className="download-app-container">
            <p>Descarga la App para hacer el match</p>
          </div>
          <img src={direction} />
        </div>
        <img src={home} /> */}
      </div>
      <img src={homeImage1} style={{ width: "100%" }} />
      <div
        className="back-ground-image-home"
      // style={{
      //   backgroundImage: `url(${homeImage1})`,
      //   width: "100%",
      //   backgroundRepeat: "no-repeat",
      //   // backgroundSize: "cover",
      //   backgroundPosition: "center",
      // }}
      >
        {/* <div className="top-bar-set">
          <Navbar restStyle={{ borderRadius: "10px" }} />
        </div> */}
        <div className="kawan-home-main-image">
          <h1>Mejora tu salud mental</h1>
          <p>
            Habla con <span style={{ fontWeight: "500" }}>Kavan</span> y
          </p>
          <p style={{ paddingTop: "0px" }}>
            encuentra tu{" "}
            <span style={{ fontWeight: "500" }}>
              profesional de la salud mental
            </span>{" "}
            en unos minutos
          </p>
          <p>Invierte en ti</p>
          <div className="home-btn-margin">
            <ReactButton
              onClick={() => executeScroll()}
              text={"Descargar ahora"}
            />
          </div>
          {/* <div className="kawan-home-app-download-image-container"></div> */}
        </div>
      </div>
      {/* <div className="elliptical"></div> */}
      <div className="kawan-home-app-comparison-container">
        <img src={comparison} />
      </div>
      <div className="kawan-hr1-container">
        <img src={hr1} />
      </div>
      <div className="kawan-home-app-source-container">
        <p>
          source: <span> Journal of Telemedicine e-health</span>
        </p>
      </div>
      <div className="kawan-home-wave">
        <img src={wave} />
      </div>
      <div className="kawan-home-session-online">
        <p>
          ¿Porqué tener <span>sesiones online</span> con Kavan Health?
        </p>
      </div>
      <div className="kawan-home-session-main-container">
        <div className="kawan-home-session-mobile-ss">
          <img src={mobileSS} />
        </div>
        <div className="kawan-home-session-vertical-line"></div>
        <div className="kawan-home-session-info-container">
          {iconArray.map((item) => {
            return (
              <div className="kawan-home-session-item">
                <img src={item.icon} />
                <h2>
                  {item.title}
                  {item.id == 2 && (
                    <span style={{ fontWeight: "700" }}>25 EUR</span>
                  )}
                </h2>
              </div>
            );
          })}
        </div>
      </div>

      <div className="kawan-home-ellipse">
        <div className="kawan-home-ellipse1">
          <img src={homeEllipse} />
        </div>
        <div className="kawan-home-ellipse2">
          <img src={hr2} alt="" />
        </div>
        <div className="kawan-home-ellipse-btn-main-container">
          <ReactButton onClick={() => executeScroll()} text={"Get Matched"} />
          <div className="kawan-join-btn-main-container">
            <p>
              ¿Eres psicólogo?{" "}
              <span style={{ color: "#006039", fontWeight: "600" }}>Únete</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <div ref={myRef}>
        <AppDownload />
      </div>

      <FooterPublic />
    </div>
  );
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Footer, TextInput, Navbar } from "../../../components";
import OtpInput from "react18-otp-input";
import "./verifyEmail.css";
import { req } from "../../../requests";
const VerifyEmail = () => {
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  const navigate = useNavigate();
  return (
    <div>
      <Navbar />
      <div className="kwn-fill_out_form-main_container">
        <div className="kwn-fill_out_form-title_container">
          <h1>Verify Email</h1>
          <h2>Enter the code was sent to your given email</h2>
        </div>
        <div className="kwn-verify_email-otp_container">
          <OtpInput
            inputStyle="kwn-verify_email-otp_style"
            numInputs={4}
            onChange={(value) => setOtp(value)}
            separator={<span> </span>}
            isInputNum={true}
            shouldAutoFocus
            value={otp}
            containerStyle="kwn-verify_email-otp_container_style"
          />
        </div>
        <div style={{ margin: '10px' }}></div>
        <TextInput type={'password'} title={'Password'} value={password} onChange={ev => setPassword(ev.target.value)} placeholder={'************'} />
        <h3 style={{ margin: '10px', color: 'red' }}>{error}</h3>
        <div className="kwn-verify_email-Button_container">
          <div style={{ marginTop: "3rem", marginRight: "0" }}>
            <Button onClick={async () => {
              let success = 
              setLoading(true)
              await req('PATCH', '/user/resetPassword', { email: email.toLowerCase().trim(), password, otp, device: { id: 'web', deviceToken: "MockToken" } }, () => {
                setError('Invalid Token')
                success = false
                setLoading(false)
              }, () => {
                console.log("Hi! Verify?")
                success = true
                // localStorage.setItem('kawan_accessToken', token)
                // localStorage.setItem('kawan_refreshToken', refreshToken)
                // console.log("ACCOUNT LINK =>", accountLink.url)
                // if(accountLink?.url) window.location.href = accountLink.url;
                window.location.href = "https://www.kavanhealth.com/log-in"
                setLoading(false)
              })
              if(success) window.location.href = "https://www.kavanhealth.com/log-in"

            }}>{loading ? 'Loading...' : 'Verify'}</Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VerifyEmail;

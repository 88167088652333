import { useState } from "react";
import { apple, blackCheck, facebookBlue, google } from "../../../assets";
import { Navbar, Footer, TextInput, Button } from "../../../components";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { req } from "../../../requests";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useGoogleLogin } from '@react-oauth/google';
import 'react-toastify/dist/ReactToastify.css';
import "./webLogin.css";
import {
  kavanLogo,
  googleLogo,
  download,
  checkbox,
  hideEyeIcon,
  showEyeIcon
} from "../../../assets";
import { useMetaPixel } from "../../../metaPixel";

const WebLogin = ({ doctors, reviews }) => {
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [nameConfirmationMessage, setNameConfirmationMessage] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("");
  const pixel = useMetaPixel()

  const login = useGoogleLogin({
    onSuccess: () => {
      pixel.trackCustom("LoginComplete", { loginType: "social-login" })
      navigate("/chatbotEnd", {
        state: { doctors, reviews }
      })
    },
  });

  console.log('webLogindoctors',doctors);
  const socialArray = [
    {
      id: 1,
      title: "Continue with Google",
      icon: google,
    },
    {
      id: 1,
      title: "Continue with Facebook",
      icon: facebookBlue,
    },
    {
      id: 1,
      title: "Continue with Apple",
      icon: apple,
    },
  ];

  const isEmailValid = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isPasswordValid = (password) => {
    // Password should have a minimum length of 6 characters
    return password.length >= 8;
  };

  const isFormIncomplete = () => {
    return (
      !email ||
      !password ||
      checkboxValue === false ||
      !isEmailValid(email) ||
      !isPasswordValid(password)
    );
  };


  return (
    <div className="mainApp">
      <div className="App">
        <img className="App-logo" src={kavanLogo} alt="Kavan Logo" />

        <p className="topText">Crear una cuenta</p>
        <div className="form-fields">
          <p className="labels">Email o usuario</p>
          <input
            // type={"text"}
            title={"Email"}
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
            type="email"
            placeholder="maildelusuario12345@gmail.com"
            className="fieldsInput"
          />
          <p className="labels">Contraseña</p>
          <div className="passwordInputContainer">
            <input
              // type={"password"}
              title={"Password"}
              value={password}
              onChange={(ev) => setPassword(ev.target.value)}
              placeholder={"************"}
              type={passwordVisible ? 'text' : 'password'}
              // placeholder="*****"
              className="fieldsInput"
            />
            {passwordVisible ? (
              <img
                src={showEyeIcon}
                className="passwordVisibilityToggle"
                alt="Password Visibility Toggle"
                style={{ opacity: 0.5 }}
                onClick={() => setPasswordVisible(false)}
              />
            ) : (
              <img
                src={hideEyeIcon}
                className="passwordVisibilityToggle"
                alt="Password Visibility Toggle"
                onClick={() => setPasswordVisible(true)}
              />
            )
            }
          </div>
        </div>
        <div className="checkboxRow">
          {checkboxValue ? (
            <img
              onClick={() => {
                setCheckboxValue(false);
              }}
              style={{ width: 20, height: 20 }}
              src={checkbox}
              alt="Checkbox Image"
            />
          ) : (
            <div
              class="custom-checkbox"
              onClick={() => {
                setCheckboxValue(true);
              }}
            />
          )}

          <p className="termsName">
            Acepto todos los <b>términos y condiciones</b>
          </p>
        </div>

        <button type="button"
          className={`submitButton ${isFormIncomplete() ? 'disabled' : ''}`}
          disabled={isFormIncomplete()}
          style={{
            color: isFormIncomplete() ? 'black' : 'white',
            cursor: isFormIncomplete() ? 'not-allowed' : 'pointer', // Set cursor style
          }}
          onClick={async () => {
            if (!isPasswordValid(password)) {
              toast.error("Password should have a minimum length of 6 characters.");
              return;
            }
            let success = true;
            // if (password != confirmPassword) return;
            setLoading(true);
            await req(
              "POST",
              "/user/signup",
              {
                name: 'default',
                email: email.toLowerCase().trim(),
                password,
                doctor: false,
              },
              (message) => {
                // console.log(22222222222, message)
                toast.error(message);
                setLoading(false);
                setError(message);
                success = false;
              }
            );
            if (!success) return;
            await req("POST", "/user/sendOTP", { email: email.toLowerCase().trim() });
            console.log('weblogin',navigate(`/otpVerification?email=${encodeURIComponent(email)}`, {
              state: {
                doctors,
                reviews
              }
            }));
            pixel.trackCustom("OTPVerificationWeb")
            navigate(`/otpVerification?email=${encodeURIComponent(email)}`, {
              state: {
                doctors,
                reviews
              }
            })
          }}
        >
          {loading ? "Loading..." : "Crear cuenta"}
          {/* Crear cuenta */}
        </button>

        <div className="middleText">
          <div className="leftLine"></div>
          <p className="middleTextStyle">O también</p>
          <div className="rightLine"></div>
        </div>

        <div className="socialButton">
          <button type="button" className="googleButton" onClick={() => login()}>
            <img src={googleLogo} className="socialImage" />
            Continuar con Google
          </button>
          <button type="button" className="appleButton">
            <img src={download} className="socialImage" />
            Continuar con Apple
          </button>
        </div>
      </div>
    </div>
  );
};

const MainLogin = () => {
  const { state: { doctors, reviews } } = useLocation()

  return <GoogleOAuthProvider clientId="557991807729-8v18jrd6su56i1dt1boiagrmp4n55nr7.apps.googleusercontent.com">
    <WebLogin doctors={doctors} reviews={reviews}/>
  </GoogleOAuthProvider>
}

export default MainLogin;
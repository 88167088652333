import React from "react";
import {
  navbarLogo,
  facebook,
  instagram,
  linkedIn,
  googleStore,
  appleStore,
} from "../../assets";
import "./footerPublic.css";

const FooterPublic = () => {
  return (
    <div className="main-container-footer_public">
      <div className="footerTopView-public">
        <div className="footerContainer">
          <div className="footerItemTopView">
            <h1>About us</h1>
            <img src={navbarLogo} alt="" />
            <p></p>
            <div className="footerSocialView">
              {/* <img src={facebook} alt="" /> */}
              <img src={linkedIn} alt="" />
              <img src={instagram} alt="" />
            </div>
          </div>
          <div className="footerItemTopView">
            <h1>Website</h1>
            <h2>Psychologists</h2>
            <h2>About us</h2>
            <h2>Contact us</h2>
            <h2 style={{ cursor: "pointer" }} onClick={() => {
                window.open("https://react-host-s3.s3.us-east-2.amazonaws.com/KAVAN_HEALTH%2C_S.L._-_MODELO_POL%C3%8DTICA_PRIVACIDAD_APP_.pdf", "_blank")
            }}>Privacy Policy</h2>
            <h2 onClick={() => {
              window.open("https://react-host-s3.s3.us-east-2.amazonaws.com/KAVAN+HEALTH+S.L.+-+T%C3%89RMINOS+Y+CONDICIONES+GENERALES+-+PROFESIONALES+(02.02.2024).pdf", "_blank")
            }}>Terms and Conditions</h2>
          </div>
          <div className="footerItemTopView">
            <h1 style={{ marginBottom: 5 }}>Download our App</h1>
            <div>
              <img
                className="socialapp"
                style={{ marginBottom: 5 }}
                alt=""
                src={appleStore}
              />
            </div>
            <img
              className="socialapp"
              style={{ marginBottom: 5 }}
              alt=""
              src={googleStore}
            />
            <h1 style={{ marginBottom: 5 }}>Support</h1>
            <h2>Chat with us</h2>
            <h2>Find Psychologists</h2>
          </div>
        </div>
        <h3 style={{ marginTop: "9rem" }}>
          Copyrights © Kavan Health 2022 | Powered by Tx Dynamics
        </h3>
      </div>
    </div>
  );
};

export default FooterPublic;

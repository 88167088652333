import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Footer, Navbar } from "../../../components";
import OtpInput from "react18-otp-input";
import "./verifyEmail.css";
import { req } from "../../../requests";
const VerifyEmail = () => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const navigate = useNavigate();
  return (
    <div>
      <Navbar />
      <div className="kwn-fill_out_form-main_container">
        <div className="kwn-fill_out_form-title_container">
          <h1>Verificar Email</h1>
          <h2>
            Introduce el código que te hemos enviado a tu correo electrónico
          </h2>
        </div>
        <div className="kwn-verify_email-otp_container">
          <OtpInput
            inputStyle="kwn-verify_email-otp_style"
            numInputs={4}
            onChange={(value) => setOtp(value)}
            separator={<span> </span>}
            isInputNum={true}
            shouldAutoFocus
            value={otp}
            containerStyle="kwn-verify_email-otp_container_style"
          />
        </div>
        <h3 style={{ margin: "10px", color: "red" }}>{error}</h3>
        <div className="kwn-verify_email-Button_container">
          <div style={{ marginTop: "3rem", marginRight: "0", opacity: otp.length == 4 ? 1 : 0.4, cursor: otp.length == 4 ? "default": "pointer" }}>
            <Button
              onClick={async () => {
                if(otp.length != 4) return
                await req(
                  "POST",
                  "/user/verify",
                  {
                    email: email.toLowerCase().trim(),
                    otp,
                    device: { id: "web", deviceToken: "MockToken" },
                  },
                  () => {
                    setError("Invalid Token");
                  },
                  ({ token, refreshToken, accountLink }) => {
                    console.log("Hi! Verify?");
                    localStorage.setItem("kawan_accessToken", token);
                    localStorage.setItem("kawan_refreshToken", refreshToken);
                    // console.log("ACCOUNT LINK =>", accountLink.url)
                    // if(accountLink?.url) window.location.href = accountLink.url;
                    navigate("/choosePofile");
                  }
                );
              }}
            >
              Verificar
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VerifyEmail;

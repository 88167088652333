import './App.css';
import { useState, useEffect } from "react";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'
import { getAllParams } from '../../../urlParams';
import { useNavigate } from 'react-router-dom';

function App() {

  var sessionContainer
  var authEndpoint = 'https://devapi.kavanhealth.com/'
  const { sessionName, userName, fromPhone } = getAllParams()
  const navigate = useNavigate()
  var config = {
      videoSDKJWT: '',
      sessionName,
      userName,
      sessionPasscode: '',
      features: ['video', 'audio', 'settings', 'users']
  };
  var role = 1

  function getVideoSDKJWT() {
    sessionContainer = document.getElementById('sessionContainer')

    document.getElementById('join-flow').style.display = 'none'

    fetch(`${authEndpoint}?sessionName=${sessionName}`, {
      method: 'POST',
      body: JSON.stringify({
        sessionName: config.sessionName,
        role: role,
      })
    }).then((response) => {
        return response.json()
    }).then((data) => {
      if(data.signature) {
        console.log(data.signature)
        config.videoSDKJWT = data.signature
        joinSession()
      } else {
        console.log(data)
      }
    }).catch((error) => {
        console.log(error)
    })
  }

  function joinSession() {
    console.log(config)
    console.log(sessionContainer)
    uitoolkit.joinSession(sessionContainer, config)
    uitoolkit.onSessionClosed(() => {
      if(fromPhone) navigate('/callEnd')
      else navigate(-1)
    })

    uitoolkit.onSessionClosed(sessionClosed)
  }

  var sessionClosed = (() => {
    console.log('session closed')
    uitoolkit.closeSession(sessionContainer)

    document.getElementById('join-flow').style.display = 'block'
  })

  useEffect(() => {
    getVideoSDKJWT()
  }, [])

  return (
    <div className="Video">
      <div className='main-video'>
        <div id="join-flow">
          {/* <h1>Zoom Video SDK Sample React</h1>
          <p>User interface offered by the Video SDK UI Toolkit</p> */}

          {/* <button onClick={getVideoSDKJWT}>Join Session</button> */}
        </div>

        <div id='sessionContainer'></div>
      </div>      
    </div>
  );
}

export default App;

import React, { useState, useEffect, forwardRef } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { calenderIcon, greenIcon } from "../../../assets";

import { Button, Footer, Navbar, TextInput } from "../../../components";
import { WithAuth } from "../../../components/auth/auth";
import { getAllParams, setParam } from "../../../urlParams";
import DropDown from "../../../components/DropDown/DropDown";
import useSaveData from "../../../hooks/useSaveData";

const Availability = () => {
  useSaveData()
  const params = getAllParams();
  const fromProfile = params.user ? true : false;
  const user = JSON.parse(params.user ?? "{}");

  console.log(user.dayEnd, user.dayStart);

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState({
    label: fromProfile
      ? new Date(user.startDateUTC)?.toString().split(" ")[4]
      : "01:00:00",
    value: `${new Date().toISOString().split("T")[0]}T${
      fromProfile ? new Date(user.startDateUTC)?.toString().split(" ")[4] : "01:00:00"
    }`,
  });
  const [endDate, setEndDate] = useState({
    label: fromProfile ? new Date(user.endDateUTC)?.toString().split(" ")[4] : "01:00:00",
    value: `${new Date().toISOString().split("T")[0]}T${
      fromProfile ? new Date(user.endDateUTC)?.toString().split(" ")[4] : "01:00:00"
    }`,
  });
  console.log("user?.dayStart", user?.dayStart)
  const [weekSelect, setWeekSelected] = useState({
    label: fromProfile
      ? (typeof user?.dayStart == "string" ? user?.dayStart : isNaN(user?.dayStart?.charAt?.(0)?.toUpperCase?.() + user?.dayStart?.slice?.(1)) ?? "Monday")
      : "Monday",
    value: fromProfile ? user.dayStart : "monday",
  });

  const [weekSelect2, setWeekSelected2] = useState({
    label: fromProfile
      ? (typeof user?.dayEnd == "string" ? user?.dayEnd : isNaN(user?.dayEnd?.charAt?.(0)?.toUpperCase?.() + user?.dayEnd?.slice?.(1)) ?? "Monday")
      : "Friday",
    value: fromProfile ? user.dayEnd : "friday",
  });

  const [startDateChanged, setStartDateChanged] = useState(false)
  const [endDateChanged, setEndDateChanged] = useState(false)

  const WeekArr = [
    { label: "Monday", value: "monday" },
    { label: "Tuesday", value: "tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "thursday" },
    { label: "Friday", value: "friday" },
    { label: "Saturday", value: "saturday" },
    { label: "Sunday", value: "sunday" },
  ];

  useEffect(() => {
    console.log(startDate);
    console.log(endDate);
  }, [startDate, endDate]);

  const SelectDate2 = forwardRef(({ value, onClick, text }, ref) => (
    <div className="kavan-select-date-container">
      {/* <button className="example-custom-input" onClick={onClick} ref={ref}>
        {value}
      </button> */}
      <p>{text}</p>
      <div className="kavan-select-date-sub_container">
        <p>{value}</p>
        <img onClick={onClick} ref={ref} src={greenIcon} />
      </div>
    </div>
  ));

  return (
    <WithAuth
      component={
        <>
          <Navbar />
          <div className="kawn-add-phone_number">
            <div className="kwn-fill_out_form-title_container">
              <h1>Availability</h1>
              <h2>Choose your days and hours for work</h2>
            </div>
            <DropDown
              options={WeekArr}
              selected={weekSelect}
              setSelected={setWeekSelected}
              text={"Day start"}
            />
            <DropDown
              options={WeekArr}
              selected={weekSelect2}
              setSelected={setWeekSelected2}
              text={"Day end"}
            />
            <DropDown
              options={new Array(24)
                .fill(0)
                .map((_, i) => i)
                .map(
                  (h) =>
                    `${new Date().toISOString().split("T")[0]}T${
                      h <= 9 ? `0${h}` : h
                    }:00:00Z`
                )
                .map((x) => {
                  console.log(x)
                  return {
                    value: x,
                    label: x.split("T")[1].split("Z").join(""),
                  };
                })}
              selected={startDate}
              setSelected={x => {
                setStartDate(x)
                setStartDateChanged(true)
              }}
              text={"Start time"}
            />

            <DropDown
              options={new Array(24)
                .fill(0)
                .map((_, i) => i)
                .map(
                  (h) =>
                    `${new Date().toISOString().split("T")[0]}T${
                      h <= 9 ? `0${h}` : h
                    }:00:00Z`
                )
                .map((x) => {
                  return {
                    value: x,
                    label: x.split("T")[1].split("Z").join(""),
                  };
                })}
              selected={endDate}
              setSelected={x => {
                setEndDate(x)
                setEndDateChanged(true)
              }}
              text={"End time"}
            />
            <div style={{ marginTop: "3.7rem" }}>
              <Button
                onClick={() => {
                  console.log("S Date, E Date =>", new Date(startDate.value).getTime(), new Date(endDate.value).getTime(), weekSelect.value, weekSelect2.value);

                  const search = setParam(fromProfile ? { _id: params._id } : {...params}, {
                    startDate: startDate.value,
                    endDate: endDate.value,
                    dayStart: weekSelect?.value,
                    dayEnd: weekSelect2?.value,
                    startDateUTC: startDateChanged ? (typeof startDate.value == "string" ? new Date(startDate.value).getTime() + new Date().getTimezoneOffset()*60_000 : startDate.value) : new Date(startDate.value).getTime() + new Date().getTimezoneOffset()*60_000,
                    endDateUTC: endDateChanged ? (typeof endDate.value == "string" ? new Date(endDate.value).getTime() + new Date().getTimezoneOffset()*60_000 : endDate.value) : new Date(endDate.value).getTime() + new Date().getTimezoneOffset()*60_000,
                  });
                  console.log(search)
                  navigate(fromProfile ? `/dashboard/SettingStack/profile?${search}` : `/registrationPending?${search}`);
                }}
              >
                Save
              </Button>
            </div>
            <div className="kawan-add_later-container">
              {/* <p>Add Later</p> */}
            </div>
          </div>
          <Footer />
        </>
      }
    />
  );
};

export default Availability;

import React from "react";
import {
    navbarLogo,

    tweetFooter,
    instaFooter,
    googleFooter,
    inFooter,
    fbFooter,
    playStore1,
    iosStore1,
} from "../../assets";
import "./style.css";

export const NewFooter = () => {
    return (
        <div className="container-footer-box">
            <div style={{ paddingBottom: 22 }} className="row-footer-view" >
                <div style={{ flex: 2 }} >
                    <img src={navbarLogo} className="kavan-footer-logo" />
                </div>
                {/* <div className="link-box-footer" >
                    <p className="footer-label" >About</p>
                    <p className="footer-link" >Link 1</p>
                    <p className="footer-link" >Link 2</p>
                    <p className="footer-link" >Link 3</p>
                    <p className="footer-link" >Link 4</p>

                </div> */}

                {/* <div className="link-box-footer" >
                    <p className="footer-label" >Support</p>
                    <p className="footer-link" >Link 1</p>
                    <p className="footer-link" >Link 2</p>
                    <p className="footer-link" >Link 3</p>
                    <p className="footer-link" >Link 4</p>

                </div> */}

                {/* <div className="link-box-footer" >
                    <p className="footer-label" >Useful Links</p>
                    <p className="footer-link" >Link 1</p>
                    <p className="footer-link" >Link 2</p>
                    <p className="footer-link" >Link 3</p>
                    <p className="footer-link" >Link 4</p>

                </div> */}


                <div className="link-box-footer" >
                    <p className="footer-label" >Support</p>
                    <p className="footer-link" >admin@kavanhealth.com</p>
                    <p className="footer-link" >+34 682 10 55 99</p>


                </div>

                <div style={{ marginLeft: 26 }} className="link-box-footer" >
                    <p className="footer-label" >Download our App</p>
                    <img onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.kavan&hl=en&pli=1") }} src={playStore1} className="icon-social-store" />
                    <img onClick={() => { window.open("https://apps.apple.com/us/app/kavan/id6448465046") }} src={iosStore1} className="icon-social-store" />

                </div>


            </div>


            <div style={{ alignItems: "center", marginTop: 16, borderWidth: 0, marginBottom: 16 }} className="row-footer-view" >
                <p className="footer-bottom-text" >All rights reserved - Kavan Health</p>
                <img onClick={() => { window.open("https://twitter.com/KavanHealth") }} src={tweetFooter} className="icon-social-footer" />
                <img src={googleFooter} className="icon-social-footer" />
                <img onClick={() => { window.open("https://www.instagram.com/kavanhealth/") }} src={instaFooter} className="icon-social-footer" />
                <img onClick={() => { window.open("https://www.linkedin.com/company/kavanhealth/") }} src={inFooter} className="icon-social-footer" />
                <img onClick={() => { window.open("https://web.facebook.com/p/Kavan-Health-Wellbeing-61553410433927/?_rdc=1&_rdr") }} src={fbFooter} className="icon-social-footer" />

            </div>


        </div>
    );
}

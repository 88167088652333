import React, { useState, useEffect } from "react";
import { chat, user, users } from "../../../assets";
import { SearchBar, Loader, Button } from "../../../components";
import { authReq } from "../../../requests";
import { jsPDF } from "jspdf";
import "./styles.css";
import { fireDB } from "../../../firebase";
import { collection, getDoc, getDocs, onSnapshot, query, where, and } from "firebase/firestore";
import { convertDateToSpanish, getAllParams, getNameShort } from "../../../urlParams";
import { useNavigate } from "react-router-dom";

const PacientDetails = (props) => {
    const [patientData, setPatientData] = useState(null);
    const [patientsAppointment, setPatientsAppointment] = useState([])
    const [notes, setNotes] = useState([])
    const [noteText, setNoteText] = useState("")
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)

    const navigate = useNavigate()

    const [patientInfo, setPatientInfo] = useState(null)

    useEffect(() => {
        props.setActive("Pacients")
        if (localStorage.getItem('user')) setUser(JSON.parse(localStorage.getItem('user')))
        else authReq("GET", "/user/me").then((data) => {
            localStorage.setItem('user', JSON.stringify(data))
            setUser(data)
        })
    }, [])


    const submitNewNote = () => {
        setLoading(true)
        const body = {
            createdAt: new Date().getTime(),
            text: noteText,
            notee: patientsAppointment[0]?.appointer?._id,
            noter: user?.data?._id
        }
        authReq("POST", "/note", body).then((response) => {
            console.log(response)
            setNoteText("")
            getAllNotes()
        })
    }


    const getAllNotes = () => {
        authReq("GET", "/note?userId=" + user?.data?._id).then((response) => {
            setNotes(response?.note)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }



    const getDataForPdf = (id) => {

        authReq("GET", "/user/getUser/" + id).then((data) => {
            setPatientInfo(data?.user?.userInfo)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)

        })
    }

    const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    const generatePdf = () => {
        const doc = new jsPDF()
        let i = 1
        let y = 0
        const multiple = 20
        for (const k of ["name", ...Object.keys(patientInfo).filter(x => x != "name")]) {
            doc.setFontSize(16);
            doc.text(`${camelToSnakeCase(k).split("_").join(" ")}: ${patientInfo[k]}`, 20, i * multiple);
            i += 1
            y += i * multiple
        }
        doc.save(`${patientsAppointment[0]?.appointer?.name}.pdf`)
    }


    useEffect(() => {
        if (user) {
            getParams()
        }
    }, [user])


    const getParams = async () => {
        let allParams = getAllParams()
        let patientId = Object.keys(allParams)[0]


        let appointmentQuery = query(collection(fireDB, "appointments"), and(
            where("appointee._id", "==", user?.data?._id),
            where('appointer._id', '==', patientId),
        ));
        const appointments = (await getDocs(appointmentQuery)).docs
        setPatientsAppointment(appointments.map(doc => doc.data()))
        getDataForPdf(appointments.map(doc => doc.data())[0].appointer?._id)
        getAllNotes()

    }


    const handleSubmit = (event) => {
        event.preventDefault();
        submitNewNote()
        // Add your form submission logic here
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevents the default action (new line) and form submission
            handleSubmit(event);
        }
    };







    return (
        <div>

            {loading && <Loader />}
            <div style={{ paddingLeft: "15%", paddingRight: "15%" }} className="kavan_admin_main_container">

                <div style={{ marginTop: 24, width: "100%" }} className="user-card" >
                    <div className="patient-header-details" >
                        {
                            patientsAppointment[0]?.appointer?.image && patientsAppointment[0]?.appointer?.image != "" && patientsAppointment[0]?.appointer?.image != "https://divet-bucket.s3.us-east-2.amazonaws.com/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper.png" ?
                                <img src={patientsAppointment[0]?.appointer?.image} alt={`profile`} className="user-image" />
                                :
                                <div style={{ backgroundColor: `#${patientsAppointment[0]?.appointer?._id?.slice(0, 6)}` }} className="user-image" >
                                    <p className="short-name" >{getNameShort(patientsAppointment[0]?.appointer?.name ?? "")}</p>
                                </div>
                        }
                        <div className="user-details">
                            <p className="user-name-2">{patientsAppointment[0]?.appointer?.name}</p>

                        </div>
                    </div>
                    <div>
                        {
                            patientInfo &&
                            <p className="pdf-download-info">{patientInfo ? "Información en pdf, chat...." : "Información no proporcionada"} </p>
                        }
                        <div className="patient-header-buttons" >
                            {
                                patientInfo &&
                                <div>
                                    <Button disabled={!patientInfo} onClick={() => { generatePdf() }} >
                                        Descargar PDF
                                    </Button>
                                </div>
                            }
                            <div className="icon-send-message-box" >
                                <img onClick={() => { navigate(`/dashboard/ChatStack?${patientsAppointment[0]?.appointer?._id}`) }} className="icon-send-message" src={chat} />
                            </div>


                            {/* <Button onClick={() => { navigate(`/dashboard/ChatStack?${patientsAppointment[0]?.appointer?._id}`) }} border={true} backgroundColor={"#D9D9D9"} >
                            Enviar mensaje
                        </Button> */}
                        </div>

                    </div>
                </div>


                <div className="detail-section-container" >

                    <textarea onKeyPress={handleKeyPress} className="detail-section-input" placeholder="Escribir una nota..." value={noteText} onChange={(data) => { setNoteText(data.target.value) }} />
                    <p style={{ textDecorationLine: "underline" }} className="detail-section-heading" >Notas</p>

                    {
                        notes.map((item) => {
                            return (
                                <div style={{ borderWidth: 0 }} className="detail-section-row" >
                                    <div>
                                        <p className="detail-section-primary-text" >{convertDateToSpanish(item?.createdAt)}</p>
                                        <p style={{ fontWeight: "lighter", marginTop: 6 }} className="detail-section-primary-text" >{item?.text}</p>

                                    </div>
                                </div>
                            )

                        })
                    }


                </div>
                <div className="detail-section-container" >
                    <p className="detail-section-heading" >Todas las Sesiones</p>

                    {
                        patientsAppointment.sort((a, b) => a?.appointmentStart > b?.appointmentStart ? 1 :-1).map((item) => {
                            return (
                                <div className="detail-section-row" >
                                    <div style={{ width: "16%", textAlign: "left" }} >
                                        <p className="detail-section-primary-text" >{convertDateToSpanish(item?.appointmentStart)}</p>
                                    </div>
                                    <div style={{ width: "14%", textAlign: "left" }} >
                                        <p className="detail-section-primary-text" >{new Date(item?.appointmentStart).toTimeString().slice(0, 5)} - {new Date(item?.appointmentEnd).toTimeString().slice(0, 5)}</p>
                                        {
                                            item?.rescheduleAppointmentStart &&
                                            <p style={{ marginTop: 8, textDecorationLine: "line-through" }} className="detail-section-text">{new Date(item?.rescheduleAppointmentStart).toTimeString().slice(0, 5)} - {new Date(item?.rescheduleAppointmentEnd).toTimeString().slice(0, 5)}</p>
                                        }
                                    </div>
                                    <div style={{ width: "14%", textAlign: "justify" }} >
                                        <p className="detail-section-primary-text">Próxima sesión</p>
                                        {
                                            item?.rescheduleAppointmentStart &&
                                            <p style={{ marginTop: 8, textDecorationLine: "line-through" }} className="detail-section-text">Sesión cancelada</p>
                                        }
                                    </div>

                                    <div style={{ width: "14%", justifyContent: "flex-end", textAlign: "justify" }} >
                                        {
                                            item?.pack ?
                                                <p className="detail-section-text">{`Sesión ${item?.numberOfSessions} de ${item?.totalSessions}`}</p>
                                                :
                                                <p className="detail-section-text">Sesión Individual</p>
                                        }
                                    </div>
                                </div>
                            )

                        })
                    }


                </div>

            </div>
        </div>
    );
};

export default PacientDetails;

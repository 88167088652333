import React, { useState, useEffect, forwardRef } from "react";
import Carousel from "react-simply-carousel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  calenderIcon,
  fb,
  clock,
  link,
  pin,
  printer,
  twitter,
} from "../../../assets";
import { Button, Footer, Navbar, TextInput } from "../../../components";
import { setParam, getAllParams } from "../../../urlParams";
import { authReq } from "../../../requests";
import DropDown from "../../../components/DropDown/DropDown";
import SelectDate from "../../../components/selectDate/selectDate";
import dayjs from "dayjs";
import WhiteNavbar from "../../../components/whiteNavbar/whiteNavbar";
import "./blog.css";
const dummyPostImage =
  "https://novathreadbucket.s3.amazonaws.com/nova-app-1685176470232-dummy.PNG";

const findInnerText = (html) => {
  if (html.childNodes[0] && html.childNodes[0].tagName != undefined)
    return findInnerText(html.childNodes[0]);
  else if (!html.childNodes[0]) return "";
  return html.innerText;
};

const untilNextH2 = (html) => {
  console.log("ELEMENT", html);
  if (!html) return [];
  if (html.tagName == "H2") return [];
  return [html, ...untilNextH2(html.nextSibling)];
};

const generateIdByText = (el) =>
  findInnerText(el)
    .split(" ")
    .join("-")
    .toLowerCase()
    .split("&nbsp")
    .join("")
    .split("\n")
    .join("")
    .split(":")
    .join("")
    .trim();

const indexClicked = (child) => {
  const id = child.toLowerCase().split(" ").join("-");
  const el = document.getElementById(id);
  console.log(id, el);
  if (el) el.scrollIntoView();
  window.location.replace(`#${id}`);
};

const AddExperience = () => {
  const { category: categoryPassed, title: titlePassed } = useParams();
  const [title, setTitle] = useState(decodeURIComponent(titlePassed));
  const [pinnedPost, setPinnedPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(categoryPassed);
  const [err, setErr] = useState("");
  const [indexLoading, setIndexLoading] = useState(true);
  const [index, setIndex] = useState([]);
  const [indexingCheck, setIndexingCheck] = useState("");
  const location = useLocation();

  const [socialIcon, setSocialIcon] = useState([]);

  useEffect(() => {
    authReq("GET", `/post/${encodeURIComponent(title)}`, null, () =>
      setErr("No Such Post Found")
    )
      .then((data) => {
        console.log(data);
        if (!data.post) return setErr("No Such Post Found");
        if (
          category.split("-").join(" ").toLowerCase() !=
          data.post.category.toLowerCase()
        )
          return setErr("No Such Post Found");
        setPinnedPost(data.post);
        setCategory(data.post.category);
        setIndexingCheck(data.post.indexing);
        setLoading(false);
        setSocialIcon(
          [
            {
              icon: fb,
              id: 1,
              link: data.post.facebook,
            },
            {
              icon: pin,
              id: 2,
              link: data.post.pinterest,
            },
            {
              icon: twitter,
              id: 3,
              link: data.post.twitter,
            },
          ].filter((x) => !!x.link)
        );
      })
      .catch((_) => setErr("No Post Found"));
  }, []);

  useEffect(() => {
    const el = document.getElementById(
      decodeURIComponent(location.hash.split("#").join(""))
    );
    if (el) el.scrollIntoView();
  }, [location]);

  useEffect(() => {
    const reText = (str) => {
      if (!str) return str;
      const x = str.split("-").join(" ");
      return x[0].toUpperCase() + x.slice(1);
    };
    if (pinnedPost) {
      setTimeout(() => {
        const html = document.getElementById("post");
        const h2s = [...html.getElementsByTagName("h2")].map((el) => {
          console.log(untilNextH2(el.nextSibling));
          return [
            el,
            untilNextH2(el.nextSibling)
              .filter((x) => !!x)
              .map((el) =>
                el.tagName == undefined
                  ? []
                  : [
                      ...el.getElementsByTagName("h3"),
                      el.tagName == "H3" ? el : null,
                      console.log(el),
                    ].filter((x) => x != null)
              )
              .reduce((a, b) => [...a, ...b], []),
          ];
        });

        for (const [h2, h3s] of h2s) {
          h2.id = generateIdByText(h2);
          for (const h3 of h3s) h3.id = generateIdByText(h3);
        }

        const el = document.getElementById(location.hash.split("#").join(""));
        console.log(el, decodeURIComponent(location.hash.split("#").join("")));
        if (el) el.scrollIntoView();

        setIndex(
          h2s
            .map(([h2, h3s]) => [
              reText(h2.id),
              h3s
                .map((el) => el.id)
                .filter((x) => !!x)
                .map((x) => reText(x)),
            ])
            .filter(([id, _]) => !!id)
        );
        setIndexLoading(false);

        // Assigning width
        for (const img of html.getElementsByTagName("img")) {
          console.log(img);
          if (img.getAttribute("height"))
            img.style.height = `${img.getAttribute("height")}px`;
          if (img.getAttribute("width"))
            img.style.width = `${img.getAttribute("width")}px`;
        }
      }, 1500);
    }
  }, [pinnedPost]);

  useEffect(() => {
    console.log(index);
  }, [index]);

  return err ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        color: "gray",
        fontSize: "10rem",
      }}
    >
      {err}
    </div>
  ) : loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    ></div>
  ) : (
    <>
      <WhiteNavbar />
      <div className="blog-main-container">
        <div className="blog-main-category-title">
          <p>{category?.toUpperCase()}</p>
        </div>
        <div className="blog-main-title">
          <h1>{pinnedPost?.title}</h1>
        </div>
        <div className="blog-main-title">
          <h3>{pinnedPost.subtitle}</h3>
        </div>
        {pinnedPost?.author && (
          <div className="blog-author-tittle">
            <p> BY {pinnedPost?.author?.toUpperCase()} </p>
          </div>
        )}
        {pinnedPost?.duration && (
          <div className="blog-author-tittle">
            <img src={clock} style={{ height: "15px", width: "15px" }} />
            <p style={{ marginTop: "unset", marginLeft: "5px" }}>
              {pinnedPost?.duration}
            </p>
          </div>
        )}
        <div className="blog-post-detail-time">
          <p>{dayjs(pinnedPost.createdAt).format("MMM DD, YYYY ")}</p>
        </div>
        <div className="blog-post-detail-social-icon-container">
          {socialIcon.map((item) => (
            <div className="blog-post-icon-container">
              <a href={item?.link} target="_blank">
                <img src={item?.icon} alt="icon" />
              </a>
            </div>
          ))}
        </div>
        <div className="read-blog-main-picture-container">
          <img
            src={
              pinnedPost.image == undefined ? dummyPostImage : pinnedPost.image
            }
          />
        </div>
        {indexingCheck == "yes" ? (
          <div
            className="blog-main-picture-container-points"
            style={{
              marginLeft: "10rem",
              marginTop: "2.5rem",
              fontFamily: "Inter, sans-serif",
              fontWeight: 500,
              fontSize: "20px",
            }}
          >
            {indexLoading ? (
              <p>Loading...</p>
            ) : (
              <ul>
                {index.map(([id, children]) => (
                  <li style={{ color: "#3D5A80", cursor: "pointer" }}>
                    <p onClick={() => indexClicked(id)}>{id}</p>
                    {
                      <ul style={{ marginLeft: "2rem" }}>
                        {children.map((child) => (
                          <li
                            style={{ color: "#3D5A80", cursor: "pointer" }}
                            onClick={() => indexClicked(child)}
                          >
                            {child}
                          </li>
                        ))}
                      </ul>
                    }
                  </li>
                ))}
              </ul>
            )}
          </div>
        ) : (
          ""
        )}

        <div
          id="post"
          className="innerHtml"
          style={{
            marginLeft: "3rem 10rem 8rem 10rem ",
          }}
          dangerouslySetInnerHTML={{ __html: pinnedPost.text }}
        ></div>
      </div>
      <Footer />
    </>
  );
};

export default AddExperience;

import React, { useState, useEffect, forwardRef } from "react";
// import Carousel from "react-simply-carousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import "./blog.css";

import { calenderIcon, rightArrow } from "../../../assets";

import { Button, Footer, Navbar, TextInput } from "../../../components";
import { setParam, getAllParams } from "../../../urlParams";
import { authReq } from "../../../requests";
import { Loader } from "../../../components"
import DropDown from "../../../components/DropDown/DropDown";
import SelectDate from "../../../components/selectDate/selectDate";
import WhiteNavbar from "../../../components/whiteNavbar/whiteNavbar";

const dummyPostImage =
  "https://novathreadbucket.s3.amazonaws.com/nova-app-1685176470232-dummy.PNG";

const AddExperience = () => {
  window.location.href = "https://www.kavanhealth.com/blog"

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [pinnedPost, setPinnedPost] = useState({
    title: "Title",
    text: "Text",
    image: dummyPostImage,
  });
  const [posts, setPosts] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    authReq("GET", "/post").then(({ posts, pinnedPost }) => {
      let lastPost = null;
      setPosts(
        posts.map(([a, b]) => {
          if (b[0] && b[0]?.priority == -1) lastPost = b[0];
          return [a, 0, b.sort((a, b) => -(b.priority - a.priority))];
        })
      );
      setLoading(false);
      setPinnedPost(pinnedPost ? pinnedPost : posts[0]);
    });
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 564, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    console.log("PINNNEDDD", pinnedPost);
  }, [pinnedPost]);

  window.location.href = "https://www.kavanhealth.com/blog"
  return <></>

  return (
    <>
      <WhiteNavbar />
      {(
        <div className="blog-main-container">
          {loading && <Loader containerWidth="80%"/>}
          <div className="blog-main-title">
            <h1>{pinnedPost?.title}</h1>
          </div>
          <div
            onClick={() =>
              navigate(
                `/blog/${encodeURIComponent(
                  pinnedPost?.category?.split(" ")?.join("-")
                )}/${encodeURIComponent(pinnedPost?.title?.split(" ")?.join("-"))}`
              )
            }
            className="blog-main-picture-container"
          >
            <img src={pinnedPost?.image ? pinnedPost?.image : dummyPostImage} />
          </div>
          <div style={{ width: "100%" }}>
            <div style={{ paddingTop: "5rem" }}></div>
            <div className="post-carousal-main-container">
              {posts.map(([title, activeSlide, items]) => (
                <div>
                  <div className="blog-post-title-container">
                    <h2>{title}</h2>
                  </div>

                  <Carousel
                    containerClass="custom-carousel-container"
                    responsive={responsive}
                    // customRightArrow={<button>ht</button>}
                  >
                    {items.map((item, index) => {
                      return (
                        <>
                          <div
                            className="blog-post-car-container"
                            // style={{ width: "350px" }}
                          >
                            <img
                              src={
                                item.image == undefined
                                  ? dummyPostImage
                                  : item.image
                              }
                              className="blog-post-image"
                              style={{
                                width: "100%",
                                height: "213px",
                                objectFit: "cover",
                              }}
                              onClick={() =>
                                navigate(
                                  `/blog/${encodeURIComponent(
                                    item.category.split(" ").join("-")
                                  )}/${encodeURIComponent(
                                    item.title.split(" ").join("-")
                                  )}`
                                )
                              }
                            />
                            <h2
                            // style={{
                            //   fontSize: "24px",
                            //   fontWeight: 500,
                            //   lineHeight: "34px",
                            //   maxWidth: "320px",
                            // }}
                            >
                              {/* Article :{index + 1}{" "} */}
                              {item?.title.length > 10
                                ? item?.title.substring(0, 50) + "..."
                                : item?.title}
                            </h2>
                            <h1
                              style={{
                                maxWidth: "100%",
                                marginTop: "1rem",
                                fontFamily: "Inter, sans-serif",
                                fontSize: "15px",
                                color: "#3f5369",
                                fontWeight: 400,
                                lineHeight: "16px",
                              }}
                            >
                              {item?.subtitle?.length > 30
                                ? item?.subtitle.substring(0, 160) + "."
                                : item?.subtitle}
                            </h1>
                            <h2
                              style={{
                                maxWidth: "100%",
                                marginTop: "1rem",
                                fontFamily: "Inter, sans-serif",
                                fontSize: "11px",
                                lineHeight: "16px",
                                color: "#3f5369",
                                fontWeight: 400,
                                fontStyle: "italic",
                              }}
                            >
                              {item?.author}
                            </h2>
                            <div className="separator"></div>
                          </div>
                        </>
                      );
                    })}
                  </Carousel>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div style={{ marginTop: "2rem" }}></div>
      <Footer />
    </>
  );
};

export default AddExperience;
// {/* <Carousel
//           containerClass="custom-carousel-container"
//           responsive={responsive}
//         >
//           {carouselItems.map((item) => (
//             <div
//               style={{ width: "30rem", height: "30rem", background: "red" }}
//               key={item.id}
//             >
//               <h3>{item.title}</h3>
//               {/* Add your carousel item content */}
//             </div>
//           ))}
//         </Carousel> */}

import React, { useState } from "react";
import Carousel from "react-simply-carousel";
import ReactSimplyCarousel from "react-simply-carousel";
import "./aboutUs.css";
import { Navbar, Footer, Button } from "../../../components";
import {
  dummy2,
  dummy3,
  dummy,
  verified,
  check,
  rightArrow,
  aboutUsImg,
  emoji1,
  aboutEllipse,
  kawan,
  listTag,
  forArrow,
  preArrow,
  emoji2,
  emoji3,
  aboutEllipse2,
  siliva,
  marcos,
  marisa,
  jaime,
  maria,
  ivan,
  camila,
} from "../../../assets";
import AppDownload from "../../../components/appDownload/appDownload";
import FooterPublic from "../../../components/footerPublic/footerPublic";

const listLink = [
  {
    id: 1,
    title: "Lorem ipsum dolor sit amet consectetur. Eget risus vulputate in ",
  },
  {
    id: 2,
    title: "Lorem ipsum dolor sit amet consectetur. Eget risus vulputate in ",
  },
];

const emojisArr = [
  {
    id: 1,
    title: "Who we are",
    des: "Kavan es una empresa de terapias online comprometida con democratizar el acceso a las terapias y mejorar el bienestar mental y emocional de las personas.",
    img: emoji1,
  },
  {
    id: 1,
    title: "What we do",
    des: "Ofrecemos terapias online accesibles y personalizadas mediante el uso de un chatbot para determinar las necesidades de los clientes y recomendar psicólogos específicos.",
    img: emoji2,
  },
  {
    id: 1,
    title: " Why we do it?",
    des: "Consideramos que todas las personas merecen tener acceso a terapias de calidad, independientemente de su situación. Trabajamos para crear un espacio donde las personas puedan convertirse en su mejor versión, ayudando así a mejorar su bienestar mental y emocional.",
    img: emoji3,
  },
];
export default function AboutUs() {
  window.location.href = "https://www.kavanhealth.com/nosotros"
  const [activeSlide, setActiveSlide] = useState(0);

  const reviewData = [
    {
      image: siliva,
      id: 1,
      name: "Silvia Estévez Touriño ",
      title: "Coach Profesional",
      des: "Mi pasión y vocación es ayudar a las personas a ser más felices, a sentirse mejor y a crear una sociedad donde reine la paz y la armonía entre los seres humanos y el resto de los seres vivos.",
    },
    {
      image: marcos,
      name: "Marcos Allende",
      id: 2,
      title: "Psicólogo Clínico",
      des: "Mi acercamiento a la terapia se basa en la idea de que ganando mayor conciencia en nuestros pensamientos, sentimientos y comportamientos, podemos aprender y desarrollar las capacidades que necesitamos para tener relaciones mas satisfactorias con nosotros mismos y con los demás, y para enfrentar mejor los desafíos de la vida.",
    },
    {
      image: marisa,
      id: 3,
      name: "Marisa Castañon",
      title: "Psicóloga Clínica",
      des: "La vida en ocasiones nos presenta cambios o situaciones que atravesar que pueden generar malestar y necesidad de pedir ayuda. O simplemente a veces buscamos cambiar la perspectiva. Escucharse e interrogarse a uno mismo en un espacio terapéutico cálido y de confianza puede ser el puntapié inicial para mejorar la salud mental y por ende la calidad de vida en general. ",
    },
    {
      image: jaime,
      id: 4,
      name: "Jaime Fernandez",
      title: "Psicólogo Clínico",
      des: "Trabajo como psicólogo clínico en el ámbito privado. Mi mayor experiencia se fue dando en temas relacionados con Trastornos de Ansiedad, Trastornos del Estado del Ánimo, Trastorno Obsesivo Compulsivo, Perfeccionismo, Procrastinación, Crisis Vitales y Desarrollo Personal.",
    },
    {
      image: maria,
      name: "Maria Gomez Rosa",
      id: 5,
      des: "Mi propuesta está orientada directamente a ser coach de vida y trabajo para conseguir que la/s persona/s en sesión conecten de manera eficaz con su Yo consciente, con sus emociones más profundas. Esta manera de acompañar aumenta exponencialmente el poder de las sesiones y que el coachée decida con más coherència y alineado con sus valores, con su esencia cual es su objetivo y cómo accionar.",
      title: "Coach profesional",
    },
    {
      image: camila,
      id: 6,
      name: "Camila Comerci",
      title: "Psicóloga Clínica",
      des: `Especialización en adolescentes y jóvenes adultos. Me dedico en atención virtual hace 4 años, el poder llegar a personas de todos los lugares del mundo, traspasar la pantalla y transmitir a través de ella.
       Aprovechar el espacio terapéutico como nuestro propio espacio, un tiempo para nosotros mismos, para escucharnos y encontrar sentidos a lo que nos pasa.`,
    },
    {
      image: ivan,
      id: 6,
      title: "Psicólogo Clínica",
      name: "Ivan Paola",
      des: "Especialización en adolescentes y jóvenes adultos; para los cuales, continúo en proceso de formación constantemente a través de seminarios y grupos de lectura.Por otro lado, mantengo una relación muy cercana con la música y las artes, lo cuál me lleva a pensar la clínica desde varios puntos de vista.",
    },
  ];

  window.location.href = "https://www.kavanhealth.com/nosotros"
  return <></>

  return (
    <div>
      {/* <Navbar /> */}
      <div className="mianContainer">
        <img src={aboutUsImg} style={{ width: "100%" }} alt="about-us-image" />

        <div className="back-ground-image-home">
          <div className="top-bar-set">
            <Navbar restStyle={{ borderRadius: "10px" }} />
          </div>
          <div className="kawan-about_us-main-image-container">
            <h1>
              About <span style={{ fontWeight: "600" }}>Kavan Health</span>
            </h1>
            <p>
              Nuestra misión en <span style={{ fontWeight: "500" }}>Kavan</span>
              : Crear un espacio donde las personas puedan convertirse en su
              mejor versión.
            </p>
            <p>
              Nuestra visión es{" "}
              <span style={{ fontWeight: "500" }}>
                romper con los establecido, conectando la inteligencia emocional
                con la salud.
              </span>
            </p>
          </div>
        </div>
        <div className="kawan-about_us-emoji-main-container">
          {emojisArr.map((item) => {
            return (
              <div className="kawan-about_us-emoji-container">
                <div className="kawan-about_us-emoji-sub-container">
                  {/* <div className="kawan-about_us-emoji-circle">
                    </div> */}
                  <img src={item.img} />
                  <h1>{item.title}</h1>
                </div>
                <p>{item.des}</p>
              </div>
            );
          })}
        </div>

        <div className="kawan-who_is-kawan-main-container">
          <img style={{ width: "100%" }} src={aboutEllipse2} />
        </div>

        <div className="kawan-about_us-who_is-professional-heading">
          <h3>Conoce a nuestros profesionales</h3>
        </div>

        <div>
          <Carousel
            innerProps={{
              style: {
                maxWidth: "100%",
                minWidth: "100%",
                marginBottom: "12rem",
              },
            }}
            containerProps={{
              style: {
                width: "100%",
                justifyContent: "space-between",
                position: "relative",
                zIndex: 10,
              },
            }}
            activeSlideIndex={activeSlide}
            infinite={false}
            onRequestChange={setActiveSlide}
            forwardBtnProps={{
              className: "kawan-carousal-btn-for",
              disabled: activeSlide == reviewData.length - 3 ? true : false,
              children: (
                <img
                  style={{ height: "2.3rem", width: "1.3rem" }}
                  src={forArrow}
                />
              ),
              style: {
                width: "4rem",
                height: "4rem",
                alignSelf: "center",
                background:
                  activeSlide == reviewData.length - 3 ? "#3D5A80" : "#275F40",
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                position: "absolute",
                right: "47%",
                top: "90%",
                cursor: "pointer",
              },
            }}
            backwardBtnProps={{
              className: "kawan-carousal-btn-back",
              children: (
                <img
                  style={{ height: "2.3rem", width: "1.3rem" }}
                  src={preArrow}
                />
              ),
              style: {
                width: "4rem",
                height: "4rem",
                alignSelf: "center",
                background: activeSlide == 0 ? "#3D5A80" : "#275F40",
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                position: "absolute",
                left: "47%",
                top: "90%",
                cursor: "pointer",
              },
            }}
            dotsNav={{
              show: false,
              itemBtnProps: {
                style: {
                  height: 16,
                  width: 16,
                  borderRadius: "50%",
                  border: 0,
                },
              },
              activeItemBtnProps: {
                style: {
                  height: 16,
                  width: 16,
                  borderRadius: "50%",
                  border: 0,
                  background: "black",
                },
              },
            }}
            itemsToShow={2}
            speed={400}
          >
            {reviewData.map((item) => {
              return (
                <div className="kawan-about_us-who_is-proffes-review-container">
                  <div className="kawan-about_us-who_is-prof-profile-container">
                    <img src={item.image} />
                    <div className="kawan-about_us-who_is-prof-profile-name-container">
                      <h2>{item.name} </h2>
                      <h3>{item.title}</h3>
                    </div>
                  </div>
                  <p>{item.des}</p>
                </div>
              );
            })}
          </Carousel>
          <div>
            <AppDownload />
          </div>
          <div style={{ marginTop: "6rem" }}></div>
        </div>

        {/* <div className="aboutTextDiv">
          <h1>About Kavan Health</h1>
        </div>
        <div className="usersTopDiv">
          <div className="kvn-about-viewers-main_container">
            <div className="usersDiv">
              <img src={verified} alt="" className="verifiedLogo" />
              <div className="verifiedDiv">
                <h1>100+ Verified Users</h1>
                <div className="userDetailView">
                  <img src={dummy} alt='' />
                  <div>
                    <h2>Theresa Webb</h2>
                    <p>psychologist</p>
                  </div>
                </div>
                <div className="userDetailView">
                  <img src={dummy2} alt='' />
                  <div>
                    <h2>Albert Flores</h2>
                    <p>psychologist</p>
                  </div>
                </div>
                <div className="userDetailView">
                  <img src={dummy3} alt='' />
                  <div>
                    <h2>Robert Fox</h2>
                    <p>psychologist</p>
                  </div>
                </div>
                <div className="seeMoreDiv">
                  <h4>See more</h4>
                  <div className="kvn-about_us-right_arrow">
                    <img src={rightArrow} alt='' />
                  </div>
                </div>
              </div>
              <div className="contactDiv">
                <img src={dummy2} alt='' />
                <h2>Daniel Kahneman</h2>
                <p>psychologist</p>
                <Button marginTop={20}>Contact</Button>
              </div>
            </div>
          </div>
          <div className="chooseView">
            <h2>Why Choose Us</h2>
            <h1>
              Our Psychologist always available 24/7 for patient psychologist
              therapy.
            </h1>
            <p>
              The its enable direct men depend highly. Ham windows sixteen who
              inquiry fortune demands.
            </p>
            <div className="divider" />
            <div className="checkView">
              <img src={check} alt='' />
              <p>Get Overview at a glance</p>
            </div>
            <div className="checkView">
              <img src={check} alt='' />
              <p>Deoposit funds easily, securlity</p>
            </div>
            <div className="checkView">
              <img src={check} alt='' />
              <p>Get Live Support</p>
            </div>
          </div>
        </div>
        <div className="detailView">
          <h2>About Kavan Health</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Habitant
            aliquet sed in suspendisse diam. Nulla egestas elementum enim, sed
            eleifend sit sed. Pulvinar ac odio porttitor nunc faucibus massa
            vitae consectetur. Elit volutpat eu non enim tellus duis ante nisi,
            fermentum. Suspendisse commodo id lobortis est. Aliquam, odio at
            massa ut gravida facilisi non dui. Erat diam, amet nulla condimentum
            nisi et quis ut justo. Non nunc sit volutpat pretium, ut. Imperdiet
            lobortis in amet, dolor vulputate amet. Facilisis at vestibulum
            turpis platea odio consectetur et, eget. Lacus vitae varius
            suspendisse arcu, non id. Quam viverra feugiat vitae dignissim
            hendrerit fusce aliquam. Adipiscing neque vel ac odio. Dolor
            placerat tortor varius nunc, bibendum. Blandit arcu consectetur
            aliquam bibendum etiam. Facilisi dolor, non porttitor integer
            sagittis, vitae malesuada vehicula. Tortor convallis scelerisque
            fames auctor eros, sed. Quisque ullamcorper cras orci vitae. Arcu
            tellus amet, tristique fermentum vitae pellentesque. Sed odio
            tincidunt fermentum odio. Lacinia risus egestas posuere orci quisque
            felis, augue vitae ornare. Sit tempus nunc velit vel facilisi
            convallis integer lacus id. Tempus aliquam nisl dis in. Morbi quam
            massa quisque ut blandit ut ac ac feugiat. Facilisis tortor,
            elementum consectetur enim tristique. Libero aenean massa odio
            tristique ullamcorper nunc donec fusce. Dignissim in ante volutpat
            diam pharetra mauris vitae nam sem. Nisl cursus vitae platea nulla
            maecenas. Sit euismod pretium, volutpat faucibus natoque non. Lorem
            amet velit in scelerisque eget laoreet morbi. Mattis ut donec
            commodo auctor pretium risus, convallis. Neque egestas aenean nulla
            sollicitudin ac enim. Elementum pellentesque nulla pretium
            vulputate. Nisl tincidunt sit id nisl elit aliquet pellentesque. Sit
            et massa nisl dictum. Tincidunt non, pellentesque tristique diam.
            Pellentesque tellus, ipsum amet sollicitudin sit. Vivamus
            ullamcorper imperdiet integer feugiat. Nulla dapibus sit vestibulum
            sit massa augue neque. Metus, turpis odio leo auctor adipiscing
            accumsan enim semper id. Volutpat neque, non sit duis leo. Sed vitae
            augue odio varius in sollicitudin etiam. Vestibulum elit massa
            pharetra, sed aliquet sem quisque. Nulla eget diam nullam tellus
            lacus, sem lorem lectus laoreet. Nisl, turpis odio turpis tellus.
            Morbi tincidunt nullam ultrices arcu dolor viverra consectetur
            libero. Pretium urna sit tellus sed varius pharetra. Nam risus
            ultricies orci, volutpat sit adipiscing. Nec, volutpat consectetur
            enim pellentesque convallis. Quam nulla quis et in purus justo,
            feugiat in venenatis. Nisl lacus, bibendum egestas risus ut euismod
            commodo dignissim. Sagittis, vel enim, amet, facilisi phasellus.
            Aliquam non nec consectetur ullamcorper sagittis purus etiam in.
            Eleifend amet quam rhoncus, elit arcu, etiam felis ultrices.
            Hendrerit sit blandit massa rhoncus pretium a cras ultrices mauris.
            Risus, aenean nibh ac urna vulputate magna magna pretium in. Elit id
            odio urna blandit tortor, vestibulum felis vitae amet. Elementum
            morbi nunc, morbi egestas tellus amet facilisis vestibulum. Suscipit
            varius aliquam venenatis, dolor justo, semper tincidunt velit, sem.
            Enim sit vitae nulla parturient amet, risus. Lectus pellentesque
            amet, augue id volutpat, cras. Ut tempus diam ultrices feugiat. Sed
            fringilla semper commodo, sed. Quis tortor lacinia nibh ultrices
            libero elit, quis et. Cursus luctus tellus in ac dui risus sit orci.
            Sapien enim at nunc sagittis. Euismod aliquet et convallis viverra.
            Facilisis a feugiat porttitor nunc turpis nunc. Dictumst sagittis
            ornare varius viverra. Eu, ullamcorper mauris lacus morbi elit.
            Justo et tempor, pretium, eu vestibulum lacus. Feugiat et amet
            maecenas convallis molestie sed. Dolor lectus nulla consectetur
            viverra scelerisque. Tellus nulla elementum tincidunt sed nulla.
            Nisi, id fringilla ipsum velit non. Vitae a morbi nisl viverra vel
            dui. Faucibus elementum nunc vestibulum vulputate feugiat. Urna,
            leo, viverra purus mauris varius amet gravida. Integer vel eget
            semper facilisis ornare semper lacus hendrerit. Arcu, euismod sed
            tortor, sed est, imperdiet ullamcorper netus. Scelerisque elementum
            parturient malesuada faucibus diam ultricies mauris. Sem eget
            faucibus sed ut adipiscing nibh pharetra vitae. Faucibus ultrices
            eget ut mattis bibendum turpis sed amet. Imperdiet sagittis viverra
            eget pharetra, mauris pellentesque mauris mattis. Placerat lectus
            tortor eu quis cras felis eu. Nullam non elit, ullamcorper lectus
            pulvinar tortor. Congue sit nam pellentesque eget. Bibendum congue
            fringilla non pulvinar. Pellentesque magna potenti gravida consequat
            amet augue at turpis erat. Curabitur tempor sapien duis phasellus.
            Sed vitae tempor dignissim egestas fermentum pulvinar non. Faucibus
            sed orci imperdiet aenean velit. Turpis quam faucibus in sagittis
            non eu lobortis. Pellentesque molestie justo bibendum consectetur
            sem ultricies mattis etiam mi. Elementum velit posuere tincidunt et
            lorem feugiat. Vestibulum id odio neque placerat ornare massa congue
            varius. Sagittis adipiscing integer diam commodo. Feugiat quisque
            convallis massa nunc, eu adipiscing. Nisi quisque sollicitudin morbi
            a magna cras bibendum dictumst fringilla. Pharetra, nibh pharetra
            placerat mus enim scelerisque facilisi. Tempus in ridiculus lacus
            augue id. Condimentum auctor nunc tincidunt venenatis fermentum sem.
            Massa duis sit dolor vulputate. Sollicitudin sit pellentesque enim
            lorem porttitor. Ipsum pretium, nulla ipsum varius faucibus nunc.
            Molestie venenatis pharetra enim varius quis nisl. Suspendisse porta
            sodales tellus neque ac id quis tincidunt. Porttitor feugiat libero
            amet arcu, sed vitae lectus. Sit dictum tellus eget ac amet, massa
            purus, dolor platea. Posuere convallis venenatis, diam turpis ut nam
            sed eu. Ac in pellentesque aliquam sed. Id nec est posuere morbi.
            Scelerisque nulla mattis eget in pellentesque ornare a. Bibendum
            congue fringilla non pulvinar. Pellentesque magna potenti gravida
            consequat amet augue at turpis erat. Curabitur tempor sapien duis
            phasellus. Sed vitae tempor dignissim egestas fermentum pulvinar
            non. Faucibus sed orci imperdiet aenean velit. Turpis quam faucibus
            in sagittis non eu lobortis. Pellentesque molestie justo bibendum
            consectetur sem ultricies mattis etiam mi. Elementum velit posuere
            tincidunt et lorem feugiat. Vestibulum id odio neque placerat ornare
            massa congue varius.{" "}
          </p>
        </div> */}
      </div>
      <FooterPublic />
    </div>
  );
}

import React, { useState } from "react";
import { apple, blackCheck, facebookBlue, google } from "../../../assets";
import { Navbar, Footer, TextInput, Button } from "../../../components";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { req } from "../../../requests";
import { useNavigate } from "react-router-dom";
import "./signUp.css";

const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

export default function SignUp() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [nameConfirmationMessage, setNameConfirmationMessage] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("");
  const socialArray = [
    {
      id: 1,
      title: "Continue with Google",
      icon: google,
    },
    {
      id: 1,
      title: "Continue with Facebook",
      icon: facebookBlue,
    },
    {
      id: 1,
      title: "Continue with Apple",
      icon: apple,
    },
  ];

  return (
    <GoogleOAuthProvider clientId="557991807729-8v18jrd6su56i1dt1boiagrmp4n55nr7.apps.googleusercontent.com">
      <Navbar />
      <div className="kwn-sign_up-main_container">
        <div className="kwn-sign_up-title_container">
          <h1>Crear una cuenta</h1>
          <h2>Regístrese para empezar a utilizar Kavan Health</h2>
        </div>

        <div className="kwn-sign_up-form_container">
          <TextInput
            type={"text"}
            title={"Full Name"}
            value={name}
            onChange={(ev) => {
              setName(ev.target.value)
              if(ev.target.value.length == 0) setNameConfirmationMessage("")
              if(ev.target.value.split("").map(x =>[...alphabet, ' '].includes(x)).filter(x => !x).length > 0) setNameConfirmationMessage("Invalid Name")
              else setNameConfirmationMessage("")
            }}
            placeholder={"John doe"}
          />
          <span style={{ color: "red" }}>{nameConfirmationMessage}</span>
          <TextInput
            type={"text"}
            title={"Email"}
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
            placeholder={"Johndoe@mail.com"}
          />
          <span style={{ color: "red" }}>{email.length == 0 ? '' : (email.includes('@') ? '' : 'Invalid Email')}</span>
          <TextInput
            type={"password"}
            title={"Password"}
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
            placeholder={"************"}
          />
          <TextInput
            type={"password"}
            title={"Confirm Password"}
            value={confirmPassword}
            onChange={(ev) => {
              setConfirmPassword(ev.target.value);
              if (ev.target.value != password)
                setConfirmationMessage(
                  "Your password does not match the confirmation password"
                );
              else setConfirmationMessage("");
            }}
            placeholder={"************"}
          />
          <span style={{ color: "red" }}>{confirmationMessage}</span>
          <div className="kwn-sign_up-terms_container">
            <p style={{ color: "red" }}>{error}</p>
          </div>
        </div>

        <div className="kwn-sign_up-Button_container">
          <Button
            disabled={confirmationMessage || (email.length == 0 ? '' : (email.includes('@') ? '' : 'Invalid Email')) || nameConfirmationMessage || password == "" || name == "" || email == ""}
            onClick={async () => {
              let success = true;
              if (password != confirmPassword) return;
              setLoading(true);
              await req(
                "POST",
                "/user/signup",
                {
                  name,
                  email: email.toLowerCase().trim(),
                  password,
                  doctor: true,
                },
                (message) => {
                  setLoading(false);
                  setError(message);
                  success = false;
                }
              );
              if (!success) return;
              await req("POST", "/user/sendOTP", { email: email.toLowerCase().trim() });
              navigate(`/verifyEmail?email=${encodeURIComponent(email)}`);
            }}
          >
            {loading ? "Loading..." : "Crear cuenta"}
          </Button>
        </div>
        <h3>OR</h3>
        <GoogleLogin
              onSuccess={async credentialResponse => {
                const jwt = credentialResponse.credential
                const decoded = jwt_decode(jwt);
                const email = decoded?.email;
                const name = decoded?.name;
                const result = { email, name };

                let success = true
                setLoading(true)
                const data = await req('POST', '/user/socialLogin', {
                  email, 
                  name,
                  device: {
                    id: "web",
                    deviceToken: "MockToken"
                  }
                }, message => {
                  setLoading(false)
                  success = false
                  setError(message)
                })
                if(!success) return
                setLoading(false)
                console.log(data)
                console.log("Tokens =>", data)
                const { user, userAlreadyExisted, token, refreshToken, accountLink } = data
                localStorage.setItem('kawan_accessToken', token)
                localStorage.setItem('kawan_refreshToken', refreshToken)
                if(!userAlreadyExisted) return navigate("/choosePofile")
                if(!user.approved) return navigate("/registrationPending")
                if(accountLink) return window.location.href = accountLink.url;
                navigate("/dashboard/AdminHome")              

                console.log(result)
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />;
      </div>
      <Footer />
    </GoogleOAuthProvider>
  );
}

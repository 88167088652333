class CacheProvider {
    constructor() {
      this.baseURL = "https://cache.kavanhealth.com"
    }
  
    async set(key, appointments) {
      await fetch(`${this.baseURL}/set`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          key,
          value: JSON.stringify(appointments),
        })
      })
    }
  
    async get(key) {
      const res = await fetch(`${this.baseURL}/get?key=${key}`)
      const { data } = await res.json()
      return data.val
    }
}

const cache = new CacheProvider()

export default cache
import React, { useState } from "react";
import "./DropDown.css";
import arrow from "./chevron-down.png";
import { arrowDown } from "../../assets";

const DropDown = ({
  disabled,
  selected,
  setSelected,
  options,
  text,
  bg,
  width,
  style,
  textStyle,
  hideLabel,
}) => {
  const [isActive, setIsActive] = useState(false);

  console.log(
    "skmsksmskmsksmks",
    {
      disabled,
      selected,
      setSelected,
      options,
      text,
      bg,
      width,
      style,
      textStyle,
      hideLabel,
    }
  )

  return (
    <div className="dropdown" style={{ ...bg, height: 53 }}>
      {!hideLabel && <p style={textStyle}>{text}</p>}
      <div
        className="dropdown-btn"
        style={{ ...bg, height: 53, borderColor: 'gray', opacity: disabled ? 0.4 : 1 }}
        onClick={(e) => !disabled && setIsActive(!isActive)}
      >
        <p style={style ? style : {}}>{selected?.label ? selected?.label : (selected?.label === '' ? selected?.label : selected)}</p>
        <img src={arrowDown} alt="icon" />
      </div>
      {isActive && (
        <div style={bg} className="dropdown-content">
          <div className="dowpdown-content-container">
            {options.map((option) => (
              <p
                onClick={() => {
                  setSelected(option);
                  setIsActive(false);
                }}
                className="dropdown-item"
              >
                {option?.label ? option?.label : option}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Footer, Navbar } from "../../../components";
import { getAllParams, setParam } from "../../../urlParams";
import { chatbotLogo, chatbot1, chatbot2, chatbot3 } from "../../../assets";

import "./chatbot.css"
import { useMetaPixel } from "../../../metaPixel";

const ChooseProfile = () => {
  const navigate = useNavigate();
  useMetaPixel(pixel => pixel.trackCustom('StartedChatbotWeb'))

  const chatbotList = [
    {
        icon: chatbot1,
        text: "Por lo general, responder a estas preguntas no debería llevar más de 5 minutos."
    },
    {
        icon: chatbot2,
        text: "A partir de ahí, te ofrecemos una primera sesión gratuita para asegurarnos de que haya afinidad antes de comenzar a trabajar juntos, haciendo un 'match' con el mejor psicólogo para ti."
    },
    {
        icon: chatbot3,
        text: "Puedes responder con tranquilidad, la información que nos proporciones no será guardada ni utilizada."
    },
  ]

  return (
    <div className="chatbot-container">
        <div className="chatbot-start-main">
            <div className="chatbot-heading">
                <img src={chatbotLogo}/>
                <h1>KAVAN</h1>
            </div>
            <div className="chatbot-desc">
                <p>Con el objetivo de ponerte en contacto con el profesional que mejor cubra tus necesidades vamos a hacerte unas preguntas.</p>
            </div>
            <div className="chatbot-main-list-container">
                {chatbotList.map(({ icon, text }) => <div className="chatbot-main-list-item">
                    <img src={icon}/>
                    <p>{text}</p>
                </div>)}
            </div>
            <div className="chatbot-main-button" onClick={() => navigate('/chatbot')}>
                <p>Empezar</p>
            </div>
            <div className="chatbot-bottom-text-container">
                <div className="chatbot-bottom-text">¿Ya tienes un cuenta?</div>
                <div className="chatbot-link" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.kavan&hl=en_US")}>Descarga la App</div>
            </div>
        </div>
    </div>
  );
};

export default ChooseProfile;

import { useState } from "react";
import "./loginOtpVerification.css";
import { req } from "../../../requests";
// import { useNavigate } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import {
  androidQR,
  appleQR,
  kavanLogo,
} from "../../../assets";
import OTPInput from "otp-input-react"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMetaPixel } from "../../../metaPixel";

const OtpVerification = () => {
  // const [OTP, setOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const navigate = useNavigate();
  const { state: { doctors, reviews } } = useLocation()
  const pixel = useMetaPixel()

  const otpInputStyle = {
    borderRadius: '20px'
  };


  console.log('loginOtpVerification', doctors)


  return (
    <div className="otpmainApp">
      <div className="otpApp">

        <img className="App-logo-Otp" src={kavanLogo} alt="Kavan Logo" />

        <p className="Verificationtext">Verificación de código de un solo uso</p>
        <p className="textDecription">Ingrese el código enviado a su correo electrónico</p>

        <div className="otpView">
          <OTPInput
            numInputs={4}
            onChange={(value) => setOtp(value)}
            separator={<span> </span>}
            isInputNum={true}
            shouldAutoFocus
            value={otp}
            inputClassName='round' autoFocus OTPLength={4} otpType="number" disabled={false} secure skipDefaultStyles />
        </div>

        <button
          onClick={async () => {
            pixel.trackCustom("LoginComplete", { loginType: "regular-login" })
            await req(
              "POST",
              "/user/verify",
              {
                email: email.toLowerCase().trim(),
                otp,
                device: { id: "web", deviceToken: "MockToken" },
              },
              (message) => {
                // console.log(22222222222, message)
                setError("Invalid Token");
                toast.error(message);
              },
              ({ token, refreshToken, accountLink }) => {
                console.log("Hi! Verify?");
                localStorage.setItem("kawan_accessToken", token);
                localStorage.setItem("kawan_refreshToken", refreshToken);
                // console.log("ACCOUNT LINK =>", accountLink.url)
                // if(accountLink?.url) window.location.href = accountLink.url;
                console.log('otpverify', navigate("/chatbotEnd", {
                  state: {
                    doctors,
                    reviews
                  }
                }));
                navigate("/chatbotEnd", {
                  state: {
                    doctors,
                    reviews
                  }
                });
              }
            );
          }}
          className="btn">
          <p className="textStyle">Enviar código</p>
        </button>

      </div>
    </div>

  );
};

export default OtpVerification;
import React from "react";
import { Navbar, Footer, TextInput, Button } from "../../../components";
import AppDownload from "../../../components/appDownload/appDownload";
import "./contactUs.css";
import FooterPublic from "../../../components/footerPublic/footerPublic";

export default function ContactUs() {

  window.location.href = "https://www.kavanhealth.com/contactanos"
  return <></>

  return (
    <div></div>
  );
  
}

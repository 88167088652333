import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Footer, Navbar, TextInput } from "../../../components";
import { authReq } from "../../../requests";
import { setParam, getAllParams } from "../../../urlParams";
import DropDown from "../../../components/DropDown/DropDown";

const qualificationArr = [
  { label: "Phd Psychology", value: "phd psychology" },
  { label: "MS Psychology", value: "ms psychology" },
];

const universityArr = [
  { label: "University of Gujrat", value: "university of gujrat" },
  { label: "University of Punjab", value: "university of punjab" },
];

const countryArr = [
  { label: "England", value: "england" },
  { label: "Denmarkt", value: "denmark" },
  { label: "Estonia", value: "estonia" },
  { label: "Finland", value: "finland" },
  { label: "Iceland", value: "iceland" },
  { label: "France", value: "france" },
];

function AddQualification() {
  const navigate = useNavigate();
  const params = getAllParams();
  const current = params.current ? JSON.parse(params.current) : {}
  console.log(current)
  const index = parseInt(params.index)

  const [selected, setSelected] = useState(current.qualification);
  const [selectUniversity, setSelectUniversity] = useState(current.university ?? '');
  const [year, setYear] = useState([
    {
      label: 2023,
      value: 2023,
    },
  ]);

  const [selectCountry, setSelectCountry] = useState(current?.country ?? '');

  const [selectYear, setSelectYear] = useState({ label: current?.year ?? 2023, value: current?.year ?? 2023 });

  useEffect(() => {
    let yr = [];
    for (var y = 2022; y > 1940; y--) {
      yr.push({ label: y, value: y });
    }
    setYear(yr);
  }, []);

  return (
    <>
      <Navbar />
      <div className="kawn-add-phone_number">
        <div className="kwn-fill_out_form-title_container">
          <h1>Add Qualification</h1>
          <h2>Add your highest qualifictaion detail here</h2>
        </div>
        <div>
          <TextInput
            style={{ width: "44rem" }}
            title={"Qualification"}
            options={qualificationArr}
            value={selected}
            onChange={(ev) => setSelected(ev.target.value)}
          />
        </div>

        <TextInput
          style={{ width: "44rem" }}
          title={"University"}
          options={universityArr}
          value={selectUniversity}
          onChange={(ev) => setSelectUniversity(ev.target.value)}
        />
        <DropDown
          text={"Year"}
          options={year}
          selected={selectYear}
          setSelected={setSelectYear}
        />

        <TextInput
          style={{ width: "44rem" }}
          title={"Country"}
          options={countryArr}
          value={selectCountry}
          onChange={(ev) => setSelectCountry(ev.target.value)}
        />

        <div style={{ marginTop: "2rem" }}>
          <Button
            onClick={() => {
              let educationArr = [];
              if (params.degrees)
                educationArr =
                  JSON.parse(decodeURIComponent(params.degrees));
                if(params.current) educationArr = educationArr.map((el, i) => i == index ? {qualification: selected, university: selectUniversity, year: selectYear.value, country: selectCountry} : el)
                else educationArr.push({
                qualification: selected,
                university: selectUniversity,
                year: selectYear.value,
                country: selectCountry,
              })

              const search = setParam(
                params,
                "degrees",
                JSON.stringify({ degrees: educationArr })
              );
              authReq('PATCH', '/user/updateProfile', {degrees: JSON.stringify({degrees: educationArr})})
              navigate(`/addDegree?${search}`);
            }}
          >
            Save
          </Button>
        </div>
        <div className="kawan-add_later-container">
          <p
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/addDegree?${window.location.href.split("?")[1]}`)
            }
          >
            Add Later
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddQualification;

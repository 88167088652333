import React, { useState, useEffect } from "react";
import { getAllParams } from "../../../urlParams";
import "./pacients.css";

function random(seed) {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

const sdbm = (str) => {
  let arr = str.split("");
  return arr.reduce(
    (hashCode, currentVal) =>
      (hashCode =
        currentVal.charCodeAt(0) +
        (hashCode << 6) +
        (hashCode << 16) -
        hashCode),
    0
  );
};

const characters = "abcdefghijklmnopqrstuvwxyz";

const Pacients = () => {
  const { accountUrl } = getAllParams() 
  console.log("Account URL", accountUrl)
  const [patientData, setPatientData] = useState([]);
  const patientSet = new Set();

  return (
    <div className="kavan_admin_main_container">
        <iframe src={accountUrl}/>
    </div>
  );
};

export default Pacients;

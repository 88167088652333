import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Footer, Navbar, TextInput } from "../../../components";
import { getAllParams, setParam } from "../../../urlParams";
import DegreeCaption from "../../../components/degreeCaption/degreeCaption";
import WhiteButton from "../../../components/whiteButton/whiteButton";

function AddExperienceDegree() {
  const navigate = useNavigate();
  const [exp, setExp] = useState(JSON.parse(getAllParams()?.exp ?? '{"exp": []}')?.exp);
  return (
    <>
      <Navbar />
      <div className="kawn-add-phone_number">
        <div className="kwn-fill_out_form-title_container">
          <h1>Add Experience</h1>
          <h2>Add your highest Experience detail here</h2>
        </div>
        {exp.map((el, n) => (
          <DegreeCaption
            year={`${el.endDate}`.split("-")[0]}
            university={el.hospital}
            degree={el.position}
            country={el.country}
            onDelete={() => {console.log(el); setExp(exp.filter(x => JSON.stringify(x) != JSON.stringify(el)))}}
            onEdit={() => navigate(`/addExperience?${setParam({
              current: JSON.stringify(el),
              index: n,
              exp: JSON.stringify(exp),
            })}`)}
          />
        ))}
        <div style={{ marginTop: "4.4rem" }}>
          <WhiteButton
            text={"ADD EXPERIENCE"}
            onClick={() =>
              navigate(`/addExperience?${setParam({ exp: JSON.stringify(exp) })}`)
            }
          />
        </div>
        <div style={{ marginTop: "15.7rem" }}>
          <Button
            onClick={() =>
              navigate(
                `/dashboard/SettingStack/profile?${setParam({
                  exp: JSON.stringify({
                    exp
                  })
                })}`
              )
            }
          >
            Save
          </Button>
        </div>
        <div className="kawan-add_later-container">
          <p
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(
                `/dashboard/SettingStack/profile?${
                  window.location.href.split("?")[1]
                }`
              )
            }
          >
            Add Later
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddExperienceDegree;

import { useEffect, useState } from "react";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga'

class Tracker {

    constructor(pixel, ga) {
        this.pixel = pixel;
        this.ga = ga;
    }

    trackCustom(ev, data) {
        this.pixel.trackCustom(ev, data)
        console.log({
            category: ev,
            label: ev,
            action: JSON.stringify(data ?? {})
        })
        this.ga.event({
            category: ev,
            label: ev,
            action: JSON.stringify(data ?? {})
        })
    }
}  

export const useMetaPixel = (f) => {
    const [pixel, setPixel] = useState(null)

    useEffect(() => {
        const advancedMatching = {}
        const options = {
            autoConfig: true,
            debug: false,
        };

        ReactPixel.init('708265867890491', advancedMatching, options);
        ReactGA.initialize("G-ES2G4RQ9K3", { debug: true });

        setPixel(new Tracker(ReactPixel, ReactGA))

        if(f) f(new Tracker(ReactPixel, ReactGA))
    }, [])

    return pixel
}
// src/components/ChatHeader.js
import React, { useEffect, useState } from 'react';
import './ChatHeader.css';
import { getNameShort } from '../../../../urlParams';
import { and, collection, onSnapshot, or, query, where } from 'firebase/firestore';
import { fireDB } from '../../../../firebase';
import { spanishDays, spanishMonths } from '../../../../components/appointmentView/AppointmentView';

const ChatHeader = ({ currentChat, user }) => {
    const [nextAppointment, setNextAppointment] = useState(null)

    useEffect(() => {
        if(!currentChat?._id || !user?._id) return
        console.log("quuuuriiiieee", user?._id, currentChat?._id)
        const q = query(collection(fireDB, 'appointments'), 
            and(
                where('appointee._id', '==', user?._id),
                where('appointer._id', '==', currentChat?._id),
            )
        )
        onSnapshot(q, async snapshot => {
            const appointments = snapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() })).sort((a, b) => a?.appointmentStart > b?.appointmentStart ? -1 : 1)
            console.log("muappp", appointments)
            const app = appointments[0]
            if(app && app?.appointmentStart > Date.now()) setNextAppointment(app)
            else setNextAppointment(null)
        })
    }, [currentChat, user])

    return (
        <div className="chat-header">
            <div style={{ backgroundColor: `#${currentChat?._id?.slice(0, 6)}` }} className="user-image" >
                <p className="short-name" >{getNameShort(currentChat?.name ?? '')}</p>
            </div>
            <h3 style={{ marginLeft: 8, fontSize: 16, flex: 1 }} >{currentChat?.name}</h3>
            <div className="chat-header-info">
                <div>
                    <h3>Próxima sesión:</h3>
                    {nextAppointment ?
                        <p>{spanishDays[new Date(nextAppointment?.appointmentStart).getDay()]}, {new Date(nextAppointment?.appointmentStart).getDate()} de {spanishMonths[new Date(nextAppointment?.appointmentStart).getMonth()]}, {new Date(nextAppointment?.appointmentStart + new Date().getTimezoneOffset()*60_000).getHours()}:00</p> :
                        <p>No Appointment Scheduled</p>
                    }
                    {/* <p>Martes, 16 de Julio, <span style={{ color: "#307B5A", fontWeight: "bold" }} >14:00</span></p> */}
                </div>

                {nextAppointment && <div className='chat-agenda-button' >
                    <p style={{ color: "black", fontWeight: "bold" }} className='chat-agenda-text' >Ir a Agenda</p>
                </div>}

            </div>

            {currentChat?.offset && <h3 style={{ color: "#3D5A80" }} >Horario actual paciente: {new Date().getTime() + new Date().getTimezoneOffset() * 60_000 + currentChat?.offset}</h3>}


        </div>
    );
};

export default ChatHeader;

// src/components/ChatInput.js
import React, { useState } from 'react';
import './ChatInput.css';
import { mic, send } from '../../../../assets';

const ChatInput = ({ onSend }) => {
    const [input, setInput] = useState('');

    const sendMessage = (e) => {

        onSend(input);
        setInput('');

    };

    return (
        <div className='inputContainer' >
            <div className="chat-input">
                {/* <form> */}
                    <input
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Escribe tu mensaje aquí"
                        type="text"
                        onKeyUp={ev => ev.key == 'Enter' && sendMessage()}
                    />

                {/* </form> */}
            </div>

            <img
                id="send-message"
                className='send-message-mic'
                src={mic}
                style={{ cursor: "pointer" }}
                onClick={() => { }}
            />

            <img
                id="send-message"
                className='send-message-icon'
                src={send}
                style={{ cursor: "pointer" }}
                onClick={() => sendMessage()}
            />

        </div>
    );
};

export default ChatInput;

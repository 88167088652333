import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute1 = (props) => {
  let auth = window.localStorage.getItem("kawan_accessToken");
  if(!auth) {
    const { kawan_accessToken } = Object.fromEntries(document.cookie.split(";").map(str => str.split("=").map(x => x.trim())))
    if(kawan_accessToken) {
      localStorage.setItem("kawan_accessToken", kawan_accessToken)
      auth = kawan_accessToken
    }
  }
  console.log(auth, Object.fromEntries(document.cookie.split(";").map(str => str.split("="))))

  return auth ? <>{props.children}</> : <Navigate to={"/signIn"} />;
};

export default PrivateRoute1;

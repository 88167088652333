import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

import { calenderIcon } from "../../../assets";

import { Button, Footer, Navbar, TextInput } from "../../../components";
import { setParam, getAllParams } from "../../../urlParams";
import { authReq } from "../../../requests";
import DropDown from "../../../components/DropDown/DropDown";
import SelectDate from "../../../components/selectDate/selectDate";

const countryArr = [
  { label: "England", value: "england" },
  { label: "Denmarkt", value: "denmark" },
  { label: "Estonia", value: "estonia" },
  { label: "Finland", value: "finland" },
  { label: "Iceland", value: "iceland" },
  { label: "France", value: "france" },
];
const AddExperience = () => {
  const navigate = useNavigate();
  const params = getAllParams();
  const index = parseInt(params.index)
  const current = params.current ? JSON.parse(params.current) : {}
  console.log("Current =>>>", current)
  const [startDate, setStartDate] = useState(current?.startDate ? new Date(current.startDate).toISOString() : new Date().toISOString());
  const [endDate, setEndDate] = useState(current?.endDate ? new Date(current.endDate).toISOString() : new Date().toISOString());
  const positionArr = [
    { label: "Psychologist", value: "Psychologist" },
    { label: "Psychiatrists", value: "psychiatrists" },
  ];
  const hospitalArr = [
    { label: "The mayo clinic", value: "the mayo clinic" },
    { label: "Alpha clinic", value: "alpha clinic" },
    { label: "Rising sun clinic", value: "rising sun clinic" },
  ];
  const [selected, setSelected] = useState(current?.position ?? '');
  const [selectHospital, setSelectHospital] = useState(current?.hospital ?? '');

  const [selectCountry, setSelectCountry] = useState(current?.country ?? '');

  const SelectDate2 = forwardRef(({ value, onClick, text }, ref) => (
    <div className="kavan-select-date-container">
      {/* <button className="example-custom-input" onClick={onClick} ref={ref}>
        {value}
      </button> */}
      <p>{text}</p>
      <div className="kavan-select-date-sub_container">
        <p>{value}</p>
        <img onClick={onClick} ref={ref} src={calenderIcon} />
      </div>
    </div>
  ));

  return (
    <>
      <Navbar />
      <div className="kawn-add-phone_number">
        <div className="kwn-fill_out_form-title_container">
          <h1>Add Experience</h1>
          <h2>Add your experience here</h2>
        </div>
        <TextInput
          style={{ width: '44rem' }}
          options={positionArr}
          value={selected}
          onChange={ev => setSelected(ev.target.value)}
          title={"Position"}
        />

        <TextInput
          style={{ width: '44rem' }}
          options={hospitalArr}
          value={selectHospital}
          onChange={ev => setSelectHospital(ev.target.value)}
          title={"Hospital Name"}
        />

        <TextInput
          type="date"
          style={{ width: '44rem' }}
          value={startDate?.split('T')?.[0]}
          onChange={ev => setStartDate(ev.target.value)}
          title={"Start Date"}
        />
        {/* <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          withPortal
          portalId="root-portal"
          customInput={<SelectDate2 text={"Start Date"} />}
          dateFormat="d MMMM yyyy"
          maxDate={new Date()}
          // fixedHeight
          // showTimeSelect
          // showTimeSelectOnly
        /> */}

        <TextInput
          type="date"
          style={{ width: '44rem' }}
          value={endDate?.split('T')?.[0]}
          onChange={ev => setEndDate(ev.target.value)}
          title={"Start Date"}
        />
        {/* <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          withPortal
          portalId="root-portal"
          customInput={<SelectDate2 text={"End Date"} />}
          dateFormat="d MMMM yyyy"
          maxDate={new Date()}
        /> */}
        <TextInput
          style={{ width: '44rem' }}
          title={"Country"}
          options={countryArr}
          value={selectCountry}
          onChange={ev => setSelectCountry(ev.target.value)}
        />
        <div style={{ marginTop: "3.7rem" }}>
          <Button onClick={() => {
            let expArr = []
            if(params.exp) expArr = JSON.parse(decodeURIComponent(params.exp))
            console.log("1 Exp Arr =>>>", expArr, params.exp)
            if(params.current) expArr = expArr.map((el, i) => i == index ? {position: selected, hospital: selectHospital, country: selectCountry, startDate, endDate } : el)
            else expArr.push({
              position: selected,
              hospital: selectHospital,
              country: selectCountry,
              startDate,
              endDate
            })
            console.log("2 Exp Arr =>>>", expArr)
            const search = setParam(params, "exp", JSON.stringify({exp: expArr}))
            authReq('PATCH', '/user/updateProfile', {exp: JSON.stringify({exp: expArr})})
            navigate(`/addExperienceDegree?${search}`)
          }}>Save</Button>
        </div>
        <div className="kawan-add_later-container">
          <p style={{ cursor: 'pointer' }} onClick={() => navigate(`/addExperienceDegree?${window.location.href.split('?')[1]}`)}>Add Later</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddExperience;

export const getAllParams = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const obj = {}

    for (let [q, a] of searchParams) obj[q] = a

    return obj
}



export const convertDateToSpanish = (timestamp) => {

    const months = {
        'Jan': 'Ene',
        'Feb': 'Feb',
        'Mar': 'Mar',
        'Apr': 'Abr',
        'May': 'May',
        'Jun': 'Jun',
        'Jul': 'Jul',
        'Aug': 'Ago',
        'Sep': 'Sep',
        'Oct': 'Oct',
        'Nov': 'Nov',
        'Dec': 'Dic'
    };

    const days = {
        'Mon': 'Lun',
        'Tue': 'Mar',
        'Wed': 'Mié',
        'Thu': 'Jue',
        'Fri': 'Vie',
        'Sat': 'Sáb',
        'Sun': 'Dom'
    };
    const englishDate = new Date(timestamp).toString().slice(0, 15);
    const englishMonth = englishDate.slice(4, 7);
    const englishDay = englishDate.slice(0, 3);

    const spanishMonth = months[englishMonth];
    const spanishDay = days[englishDay];

    return englishDate.replace(englishMonth, spanishMonth).replace(englishDay, spanishDay);
}

export const getNameShort = (name) => {
    let splitArray = name.split(" ")
    if (splitArray.length > 1) {
        return splitArray[0].charAt(0).toUpperCase() + splitArray[1].charAt(0).toUpperCase()
    }
    return name.slice(0, 2).toUpperCase()
}

export const setParam = (params, k, v) => {
    if (typeof k == 'object') {
        const paramArr = []
        for (const k in params) paramArr.push([k, params[k]])
        for (const key in k) paramArr.push([key, k[key]])
        return paramArr.map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join('&')
    } else {
        params[k] = v
        const paramArr = []
        for (const k in params) paramArr.push([k, params[k]])
        return paramArr.map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join('&')
    }
}
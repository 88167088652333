import React from "react";
import { androidQR, appleQR } from "../../assets";
import "./appDownload.css";

const AppDownload = () => {
  return (
    <div className="kawan-home-app-download">
      <h1>Descárgate la App</h1>
      <div className="kawan-ap-download-qr">
        <img onClick={() => window.open("https://apps.apple.com/us/app/kavan/id6448465046")} src={appleQR} />
        <img onClick={() => window.open("https://play.google.com/store/apps/details?id=com.kavan&hl=en_US")} src={androidQR} />
      </div>
    </div>
  );
};

export default AppDownload;
